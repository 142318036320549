<template>
  <div class="home">
    <h1>Laporan Transaksi</h1>

    <div class="content">
      <div class="side">
        <div class="perusahaan">
          <ul>
            <li
              @click="load(-1)"
              :class="{ active: -1 == filter.id_perusahaan }"
            >
              <span>Semua</span>
            </li>
            <li
              v-for="(item, index) in dataPerusahaan"
              :key="index"
              :class="{ active: item.id == filter.id_perusahaan }"
              @click="load(item.id)"
            >
              <span>{{ item.nama }}</span>
            </li>
          </ul>
        </div>
      </div>
      <div class="main">
        <div style="display: flex; justify-content: space-between">
          <div>
            <h3>TOTAL : {{ total | number }}</h3>
          </div>
          <div class="filter">
            <p>Filter</p>
            <form @submit.prevent="load(filter.id_perusahaan)">
              <select v-model="filter.id_pos">
                <option value="">SEMUA POS</option>
                <option
                  v-for="(item, index) in dataPos"
                  :key="index"
                  :value="item.id"
                >
                  {{ item.nama }}
                </option>
              </select>
              <div>
                <date-range-picker
                  v-model="dateRange"
                  :auto-apply="true"
                  :ranges="false"
                >
                  <template v-slot:input="picker">
                    {{ moment(picker.startDate).format("DD MMM YYYY") }} -
                    {{ moment(picker.endDate).format("DD MMM YYYY") }}
                  </template>
                </date-range-picker>
              </div>
              <button type="submit" class="btn btn-filter">FILTER</button>
            </form>
          </div>
        </div>

        <div class="table-wrapper">
          <table v-show="filter.id_perusahaan == -1">
            <thead>
              <tr>
                <th style="width: 70px">#</th>
                <th>POS</th>
                <th style="text-align: right">TOTAL</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in data" :key="index" class="no-hover">
                <td
                  v-if="item.group"
                  colspan="3"
                  style="background-color: #f7f7f7"
                >
                  <b>{{ item.perusahaan }}</b>
                </td>

                <td v-if="item.pos">{{ item.no }}</td>
                <td v-if="item.pos">{{ item.pos }}</td>
                <td v-if="item.pos" align="right">
                  {{ item.totalPerPos | number }}
                </td>

                <td v-if="item.totalPerGroup" colspan="2">
                  <b>TOTAL</b>
                </td>
                <td v-if="item.totalPerGroup" align="right">
                  <b>{{ item.totalPerGroup | number }}</b>
                </td>

                <td
                  v-show="item.empty"
                  colspan="3"
                  style="border-top: 2px solid #ccc"
                >
                  &nbsp;
                </td>
              </tr>
            </tbody>
          </table>

          <table v-show="filter.id_perusahaan != -1">
            <thead>
              <tr>
                <th style="width: 70px;">#</th>
                <th>Tanggal</th>
                <th>Keterangan</th>
                <th style="text-align: right">Jumlah</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in data" :key="index" class="no-hover">
                <td
                  v-show="item.group"
                  colspan="4"
                  style="background-color: #f7f7f7"
                >
                  <b>{{ item.pos }}</b>
                </td>

                <td v-show="item.id">{{ ++index }}</td>
                <td v-show="item.id">
                  {{ moment(item.tgl_transaksi).format("DD MMM YYYY") }}
                </td>
                <td v-show="item.id">{{ item.keterangan }}</td>
                <td v-show="item.id" align="right">
                  {{ item.jumlah | number }}
                </td>

                <td v-show="item.totalPerGroup" colspan="3">
                  <b>TOTAL</b>
                </td>
                <td v-show="item.totalPerGroup" align="right">
                  <b>{{ item.totalPerGroup | number }}</b>
                </td>

                <td
                  v-show="item.empty"
                  colspan="4"
                  style="border-top: 2px solid #ccc"
                >
                  &nbsp;
                </td>
              </tr>
              <tr v-if="data.length <= 0">
                <td colspan="4">
                  <p style="padding: 100px 0; text-align: center">
                    Tidak ada data.
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { apiGet } from "../api";

import _ from "lodash";
import moment from "moment";

import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

export default {
  name: "Order",
  components: {
    DateRangePicker,
  },
  data() {
    return {
      dataPerusahaan: [],
      dataPos: [],
      data: [],

      filter: {
        id_pos: "",
        id_perusahaan: null,
      },

      dateRange: {
        startDate: moment().format("YYYY-MM-01"),
        endDate: moment().format("YYYY-MM-DD"),
      },

      total: 0,
    };
  },
  methods: {
    moment,
    async loadPerusahaan() {
      this.$loading(true);

      const res = await apiGet(`/v1/perusahaan`);

      setTimeout(() => {
        this.dataPerusahaan = res.data;
        this.$loading(false);
      }, 150);
    },
    async loadPos() {
      const res = await apiGet(`/v1/pos`);

      setTimeout(() => {
        this.dataPos = res.data;
      }, 150);
    },
    async load(id_perusahaan) {
      this.total = 0;

      this.filter.id_perusahaan = id_perusahaan;
      this.filter.tgl_awal = moment(this.dateRange.startDate).format(
        "YYYY-MM-DD"
      );
      this.filter.tgl_akhir = moment(this.dateRange.endDate).format(
        "YYYY-MM-DD"
      );

      const currentURL = new URL(window.location.href);

      const queryParams = new URLSearchParams(currentURL.search);
      queryParams.set("id_perusahaan", this.filter.id_perusahaan);
      queryParams.set("tgl_awal", this.filter.tgl_awal);
      queryParams.set("tgl_akhir", this.filter.tgl_akhir);

      currentURL.search = queryParams.toString();

      window.history.pushState({}, "", currentURL.toString());

      this.$loading(true);

      const res = await apiGet(
        `/v1/transaksi?${this.$objectToQuery(this.filter)}`
      );

      let data = [];
      const dataGroup = _.groupBy(
        res.data,
        this.filter.id_perusahaan == -1 ? "perusahaan" : "pos"
      );
      const posKey = Object.keys(dataGroup).map((item) => {
        if (item != "null") {
          return item;
        }
      });

      if (this.filter.id_perusahaan == -1) {
        posKey.forEach((key) => {
          // key = nama perusahaannya, ex: PSPP JOGJA,
          if (key) {
            data.push({
              group: true,
              perusahaan: key,
            });
          }

          if (dataGroup[key]) {
            let totalPerGroup = 0;

            const groupPerPos = _.groupBy(dataGroup[key], "pos");

            Object.keys(groupPerPos).forEach((item, index) => {
              let totalPerPos = 0;

              groupPerPos[item].forEach((pos) => {
                totalPerPos += parseInt(pos.jumlah);
              });

              data.push({
                no: ++index,
                pos: item,
                totalPerPos,
              });
              totalPerGroup += totalPerPos;
            });

            data.push({
              totalPerGroup,
            });

            data.push({
              empty: true,
            });
          }
        });
      } else {
        posKey.forEach((key) => {
          if (key) {
            data.push({
              group: true,
              pos: key,
            });
          }

          if (dataGroup[key]) {
            let totalPerGroup = 0;
            dataGroup[key].forEach((item) => {
              data.push(item);
              totalPerGroup += parseInt(item.jumlah);
            });

            if (this.filter.id_perusahaan != -1) {
              data.push({
                totalPerGroup,
              });
              data.push({ empty: true });
            }
          }
        });
      }

      setTimeout(() => {
        this.data = data;

        this.data.forEach((item) => {
          if (item.jumlah) {
            this.total += parseInt(item.jumlah);
          }
          if (item.totalPerPos) {
            this.total += parseInt(item.totalPerPos);
          }
        });

        this.$loading(false);
      }, 150);
    },
  },
  mounted() {
    this.loadPerusahaan();
    this.loadPos();

    if (this.$route.query) {
      this.filter = this.$route.query;
      this.load(this.filter.id_perusahaan);
    } else {
      this.filter.tgl_awal = moment(this.dateRange.startDate).format(
        "YYYY-MM-DD"
      );
      this.filter.tgl_akhir = moment(this.dateRange.endDate).format(
        "YYYY-MM-DD"
      );
    }

    this.$store.commit("setting/setActivePage", "resume/transaksi");
  },
};
</script>

<style lang="scss" scoped>
.content {
  display: grid;
  grid-template-columns: 250px 1fr;
  gap: 20px;
  margin-top: 50px;
  height: calc(100vh - 180px);

  .side {
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    overflow: auto;

    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;

      li {
        span {
          display: block;
          padding: 15px;
          cursor: pointer;
          border-radius: 6px;
          &:hover {
            background: #eee;
          }
        }
        &.active {
          span {
            background: var(--blue);
            color: #fff;
          }
        }
      }
    }
  }

  .main {
    border-radius: 10px;

    .filter {
      position: relative;
      margin-bottom: 20px;

      p {
        position: absolute;
        font-size: 14px;
        top: -40px;
      }
    }
    form {
      display: grid;
      grid-template-columns: 200px 250px 100px;
      grid-column-gap: 15px;

      .btn.btn-filter {
        background-color: var(--green) !important;
        border: 0 !important;
      }
    }
  }
}
.table-wrapper {
  height: calc(100vh - 200px);
}
</style>