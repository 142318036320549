<template>
  <div class="home">
    <h1>Laporan Pembayaran Siswa</h1>

    <div class="content">
      <div class="top-bar">
        <div class="filter">
          <p>Filter Kampus & Angkatan</p>
          <form @submit.prevent="load()">
            <select v-model="filter.id_kampus" @change="load()">
              <option
                v-for="(item, index) in dataKampus"
                :key="index"
                :value="item.id"
              >
                {{ item.nama }}
              </option>
            </select>
            <select v-model="filter.angkatan" @change="load()">
              <option
                v-for="(item, index) in dataAngkatan"
                :key="index"
                :value="item"
              >
                {{ item }}
              </option>
            </select>
          </form>
        </div>
        <div class="filter">
          <p>Filter Status Pembayaran</p>
          <form @submit.prevent="load()">
            <select v-model="filter.status">
              <option value="SEMUA">Semua</option>
              <option value="LUNAS">Sudah Lunas</option>
              <option value="BELUM LUNAS">Belum Lunas</option>
            </select>
            <select v-model="filter.status">
              <option value="SEMUA">Semua</option>
              <option value="LUNAS">TAHAP 1</option>
              <option value="LUNAS">TAHAP 2</option>
              <option value="LUNAS">TAHAP 3</option>
            </select>
            <button type="submit" class="btn btn-filter">FILTER</button>
          </form>
        </div>
      </div>

      <div class="table-wrapper">
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>No Daftar</th>
              <th>Siswa</th>
              <th>Jurusan</th>
              <th>Biaya Pendidikan</th>
              <th>Jumlah Bayar</th>
              <th>Kurang Bayar</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in data"
              :key="index"
              :class="{ kurang: item.kurang_bayar < 0 }"
              @click="
                $to(`/pembayaran-siswa/${item.id}?no_daftar=${item.no_daftar}`)
              "
            >
              <td>{{ ++index }}</td>
              <td>{{ item.no_daftar }}</td>
              <td>{{ item.nama_lengkap }}</td>
              <td>{{ item.jurusan }}</td>
              <td align="right">{{ item.biaya_pendidikan | number }}</td>
              <td align="right">{{ item.total_bayar | number }}</td>
              <td align="right">{{ item.kurang_bayar | number }}</td>
              <td>{{ item.status_pembayaran }}</td>
            </tr>

            <tr v-show="data.length <= 0" class="no-hover">
              <td colspan="8">
                <p style="text-align: center; padding: 100px 0">
                  Tidak ada data
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="modal" v-if="$route.params.id" @click="closeModal">
      <div class="modal-body">
        <PembayaranSiswaForm @reload="load()" />
      </div>
    </div>
  </div>
</template>
  
  <script>
import moment from "moment";

import { apiGet, apiPost } from "../api";

import PembayaranSiswaForm from "./PembayaranSiswaForm.vue";

export default {
  name: "PembayaranSiswa",
  components: {
    PembayaranSiswaForm,
  },
  data() {
    return {
      modal: false,
      data: [],

      dataKampus: [
        {
          id: 1,
          nama: "LAMPUNG",
        },
        {
          id: 2,
          nama: "JAKARTA",
        },
        {
          id: 3,
          nama: "YOGYAKARTA",
        },
        {
          id: 5,
          nama: "MAKASSAR",
        },
        {
          id: 6,
          nama: "BALI",
        },
      ],
      dataAngkatan: ["JUNI 2023","DESEMBER 2023" ],

      filter: {
        id_kampus:
          this.$store.state.setting.login.akses == "CABANG"
            ? $store.state.setting.login.id_kampus
            : 1,
        status: "SEMUA",
        angkatan: 'JUNI 2023'
      },
    };
  },
  methods: {
    moment,
    openModal(val) {
      this.modal = val;
    },
    closeModal(e) {
      if (e.target.classList[0] == "modal") {
        this.$router.back();
      }
    },
    async loadPerusahaan() {
      this.$loading(true);

      const res = await apiGet(`/v1/perusahaan`);
      this.dataPerusahaan = res.data;
    },
    async load() {
      this.$loading(true);
      const filter = this.$objectToQuery(this.filter);

      const res = await apiGet(`/v1/pembayaran-siswa/semua?${filter}`);

      setTimeout(() => {
        this.data = res.data;
        this.$loading(false);
      }, 150);
    },
    async hapus(item) {
      if (confirm("Hapus?")) {
        const res = await apiPost(`/v1/pengajuan/delete`, { id: item.id });
        if (res.valid) {
          this.load();
        }
      }
    },
  },
  mounted() {
    this.$store.commit("setting/setActivePage", "laporan-pembayaran");

    this.load();
  },
};
</script>
  
  <style lang="scss" scoped>
.top-bar {
  margin-top: 45px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;

  a {
    width: 150px;
    text-align: center;
  }

  .filter {
    position: relative;

    p {
      position: absolute;
      font-size: 14px;
      top: -40px;
    }
  }
  form {
    display: grid;
    grid-template-columns: 200px 200px 100px;
    &.ho {
      grid-template-columns: 200px 200px 200px 100px;
    }
    grid-column-gap: 15px;

    .btn.btn-filter {
      background-color: var(--green) !important;
      border: 0 !important;
    }
  }
}
.table-wrapper {
  height: calc(100vh - 190px);
  .kurang {
    color: #f00;
  }
}
</style>