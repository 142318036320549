<template>
  <div class="home">
    <h1>Transaksi</h1>

    <div class="content">
      <div class="top-bar">
        <div>
          <router-link to="/transaksi/-1" class="btn">INPUT TRANSAKSI</router-link>
        </div>
        <div class="filter">
          <p>Filter</p>
          <form @submit.prevent="load()" :class="$store.state.setting.login.akses == 'HEAD OFFICE' ? 'ho' : ''
            ">
            <div>
              <date-range-picker v-model="dateRange" :auto-apply="true" :ranges="false">
                <template v-slot:input="picker">
                  {{ moment(picker.startDate).format("DD MMM YYYY") }} -
                  {{ moment(picker.endDate).format("DD MMM YYYY") }}
                </template>
              </date-range-picker>
            </div>
            <select v-model="filter.id_perusahaan" v-if="$store.state.setting.login.akses == 'HEAD OFFICE'">
              <option value="-1">Semua Perusahaan</option>
              <option :value="item.id" v-for="(item, index) in dataPerusahaan" :key="index">
                {{ item.nama }}
              </option>
            </select>
            <button type="submit" class="btn btn-filter">FILTER</button>
          </form>
        </div>
      </div>

      <div class="table-wrapper">
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>Tgl Transaksi</th>
              <th>Pos</th>
              <th>Keterangan</th>
              <th style="text-align: right">Uang Masuk</th>
              <th style="text-align: right">Uang Keluar</th>
              <th style="text-align: right">Saldo</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in data" :key="index">
              <td v-if="item.group" colspan="8" style="background: #f7f7f7;text-transform: uppercase;">
                <b>{{ item.perusahaan }}</b>
              </td>

              <td v-if="!item.group">{{ ++index }}</td>
              <td v-if="!item.group">
                {{
                  item.tgl_transaksi
                  ? moment(item.tgl_transaksi).format("DD MMM YYYY")
                  : "-"
                }}
              </td>
              <td v-if="!item.group">{{ item.pos }}</td>
              <td v-if="!item.group">{{ item.keterangan }}</td>
              <td v-if="!item.group" align="right">
                {{ item.tipe == "UANG MASUK" ? item.jumlah : "0" | number }}
              </td>
              <td v-if="!item.group" align="right">
                {{ item.tipe == "UANG KELUAR" ? item.jumlah : "0" | number }}
              </td>
              <td v-if="!item.group" align="right">{{ item.saldo | number }}</td>
              <td v-if="!item.group" align="right" style="width: 100px">
                <span class="btn-bukti" @click="lihatBukti(item)">Lihat bukti</span>
              </td>
            </tr>
          </tbody>
        </table>

        <div class="loading" v-show="loading">
          <div class="dot-loader"></div>
        </div>

        <p style="text-align: center; padding: 120px 0" v-show="data.length <= 0 && !loading">
          Tidak ada data
        </p>
      </div>
    </div>

    <div class="modal" v-if="$route.params.id" @click="closeModal">
      <div class="modal-body">
        <TransaksiForm @reload="load()" />
      </div>
    </div>

    <div class="modal" v-if="selected.bukti" @click="closeModal">
      <div class="modal-body" style="max-width: 700px">
        <h3 style="margin: 0">{{ selected.keterangan }}</h3>
        <p>
          Bukti transaksi:<br /><br />
          <img :src="selected.bukti" width="100%" />
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { apiGet } from "../api";

import moment from "moment";

import TransaksiForm from "./TransaksiForm.vue";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

export default {
  name: "Transaksi",
  components: {
    TransaksiForm,
    DateRangePicker,
  },
  data() {
    return {
      loading: false,

      dataPerusahaan: [],
      data: [],

      dateRange: {
        startDate: moment().format("YYYY-MM-01"),
        endDate: moment().format("YYYY-MM-DD"),
      },

      filter: {
        id_perusahaan:
          this.$store.state.setting.login.akses == "HEAD OFFICE"
            ? -1
            : this.$store.state.setting.login.id_perusahaan,
      },

      selected: {},
    };
  },
  methods: {
    moment,
    closeModal(e) {
      if (e.target.classList[0] == "modal") {
        if (this.selected.bukti) {
          this.selected = {};
        } else {
          this.$router.back();
        }
      }
    },
    async loadPerusahaan() {
      const res = await apiGet(`/v1/perusahaan`);
      this.dataPerusahaan = res.data;
    },
    async load() {
      this.loading = true;

      this.filter.tgl_awal = moment(this.dateRange.startDate).format(
        "YYYY-MM-DD"
      );
      this.filter.tgl_akhir = moment(this.dateRange.endDate).format(
        "YYYY-MM-DD"
      );

      const res = await apiGet(
        `/v1/transaksi?${this.$objectToQuery(this.filter)}`
      );

      setTimeout(() => {
        this.data = res.data;

        if (this.$store.state.setting.login.akses == "HEAD OFFICE") {
          const group = _.groupBy(this.data, "perusahaan");

          this.data = [];
          Object.keys(group).forEach((perusahaan) => {
            this.data.push({
              group: true,
              perusahaan,
            });

            group[perusahaan].forEach((item) => {
              this.data.push(item);
            });
          });
        }

        this.loading = false;
      }, 150);
    },
    lihatBukti(item) {
      setTimeout(() => {
        this.selected = item;
      }, 150);
    },
  },
  mounted() {
    this.$store.commit("setting/setActivePage", "transaksi");
    this.loadPerusahaan();
    this.load();
  },
};
</script>

<style lang="scss" scoped>
.top-bar {
  margin-top: 45px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;

  a {
    width: 150px;
    text-align: center;
  }

  .filter {
    position: relative;

    p {
      position: absolute;
      font-size: 14px;
      top: -40px;
    }
  }

  form {
    display: grid;
    grid-template-columns: 250px 100px;
    grid-column-gap: 15px;

    &.ho {
      grid-template-columns: 250px 200px 100px;
    }

    .btn.btn-filter {
      background-color: var(--green) !important;
      border: 0 !important;
    }
  }
}

.table-wrapper {
  height: calc(100vh - 190px);
}

.btn-bukti {
  color: var(--blue);
  padding: 4px 8px;
  border-radius: 6px;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    background: var(--blue-hover);
    color: #fff;
  }
}
</style>

<style lang="scss">
.daterangepicker.openscenter {
  right: 0 !important;
  left: unset !important;
  transform: unset !important;
}
</style>