import Vue, { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-default.css";

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyDeS5F6hF9aRvqfvxs8xIyfTfHmtyQOyZM",
  authDomain: "pspp-keuangan.firebaseapp.com",
  projectId: "pspp-keuangan",
  storageBucket: "pspp-keuangan.appspot.com",
  messagingSenderId: "616740568629",
  appId: "1:616740568629:web:5d462e63108d6c7b087b7c",
  measurementId: "G-6M6D5N4RBG",
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

Vue.use(VueToast, {
  position: "top-right",
  type: "default",
});

Vue.config.productionTip = false;

Vue.prototype.$to = (url) => {
  setTimeout(() => {
    router.push(url);
  }, 150);
};

Vue.prototype.$loading = (val) => {
  store.commit("setting/setLoading", val);
};

Vue.prototype.$toast = (text) => {
  Vue.$toast.open(text);
};

Vue.prototype.$objectToQuery = (params) => {
  return Object.keys(params)
    .map((key) => key + "=" + params[key])
    .join("&");
};

Vue.filter("number", function (value) {
  if (value || value == 0)
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  else return "";
});

Vue.directive("thousand-separator", {
  update(el) {
    // let value = parseInt(el.value.replace(/[^0-9]+/g, ""));
    let value = parseInt(el.value.replace(/[^\d-]+/g, ""));

    if (!isNaN(value)) {
      el.value = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }

    // if (!isNaN(value)) {
    //   let isNegative = false;
    //   if (value < 0) {
    //     isNegative = true;
    //     value = Math.abs(value);
    //   }
    //   el.value =
    //     (isNegative ? "-" : "") +
    //     value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    // }
  },
});

Vue.directive("select-all", {
  inserted(el) {
    el.addEventListener("focus", function () {
      this.select();
    });
  },
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
