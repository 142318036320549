<template>
  <div class="home">
    <h1>Realisasi {{ data.perusahaan }}</h1>

    <div class="content">
      <div class="table-inline">
        <div class="realisasi-bar">
          <table>
            <tr>
              <td>Tgl Realisasi</td>
              <td>:</td>
              <td>
                <date-range-picker v-model="date" :single-date-picker="true" :auto-apply="true" :ranges="false">
                  <template v-slot:input="picker">
                    {{ moment(picker.startDate).format("DD MMM YYYY") }}
                  </template>
                </date-range-picker>
              </td>
            </tr>
          </table>
          <p class="sudah-realisasi" v-if="data.is_realisasi">
            Pengajuan sudah direalisasi pada
            {{ moment(data.updated_at).format("DD-MM-YYYY HH:mm") }} - <span @click="lihatBukti = true">Lihat
              bukti</span>
          </p>
        </div>

        <div class="table-wrapper">
          <table>
            <thead>
              <tr>
                <th style="width: 50px">#</th>
                <th>Tanggal</th>
                <th>Kebutuhan</th>
                <th style="text-align: right">Jumlah</th>
                <th style="text-align: right; width: 200px">Realisasi</th>
                <th>Keterangan</th>
                <th style="width: 100px"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in dataPengajuan" :key="index">
                <td>{{ ++index }}</td>
                <td>{{ moment(item.tgl_pengajuan).format("DD/MM/YYYY") }}</td>
                <td>{{ item.kebutuhan }}</td>
                <td align="right">{{ item.jumlah | number }}</td>
                <td>
                  <input type="jumlah" v-model="item.realisasi" v-thousand-separator v-select-all
                    style="text-align: right" />
                </td>
                <td>
                  <input type="text" v-model="item.keterangan">
                </td>
                <td align="right">
                  <span v-show="item.bukti" class="btn-bukti" @click="setSelected(index - 1)">
                    Lihat bukti
                  </span>
                </td>
              </tr>

              <tr v-show="data.length <= 0" class="no-hover">
                <td colspan="8">
                  <p style="text-align: center; padding: 100px 0">
                    Tidak ada data
                  </p>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr class="separator">
                <td colspan="3">
                  <b>TOTAL</b>
                </td>
                <td align="right">
                  <b>{{ totalPengajuan | number }}</b>
                </td>
                <td align="right">
                  <b>{{ totalDirealisasi | number }}</b>
                </td>
                <td colspan="2"></td>
              </tr>
              <tr>
                <td colspan="3">
                  <b>SALDO KEMARIN</b>
                </td>
                <td></td>
                <td align="right">
                  <input type="jumlah" v-model="data.saldo_kemarin" v-thousand-separator v-select-all
                    style="text-align: right" />
                </td>
                <td colspan="2"></td>
              </tr>
              <tr>
                <td colspan="3">
                  <b>KEKURANGAN</b>
                </td>
                <td></td>
                <td align="right">
                  <b>{{ kekurangan | number }}</b>
                </td>
                <td colspan="2"></td>
              </tr>
              <tr>
                <td colspan="3">
                  <b>PETTY CASH</b>
                </td>
                <td></td>
                <td align="right">
                  <input type="jumlah" v-model="data.petty_cash" v-thousand-separator v-select-all
                    style="text-align: right" />
                </td>
                <td colspan="2"></td>
              </tr>
              <tr>
                <td colspan="3">
                  <b>TOTAL REALISASI</b>
                </td>
                <td></td>
                <td align="right">
                  <b>{{ totalRealisasi | number }}</b>
                </td>
                <td colspan="2"></td>
              </tr>
              <tr>
                <td colspan="3">
                  <b>PEMBULATAN</b>
                </td>
                <td></td>
                <td align="right">
                  <b>{{ pembulatan | number }}</b>
                </td>
                <td colspan="2"></td>
              </tr>
            </tfoot>
          </table>
        </div>

        <div class="btn-group">
          <button type="button" class="btn" @click="validasi()" v-if="$store.state.setting.login.id != 3"
            :disabled="data.is_realisasi">
            VALIDASI
          </button>

          <button type="button" class="btn btn-green" @click="openModalRealisasi()"
            v-if="$store.state.setting.login.id == 3 || $store.state.setting.login.id == 1">
            REALISASI
          </button>

          <button type="button" class="btn btn-outline" @click="$router.back()">
            KEMBALI
          </button>
        </div>
      </div>
    </div>

    <div class="modal" v-if="selected.id" @click="closeModal">

      <div class="btn-prev" @click="prev()">
        <img src="https://img.icons8.com/?size=30&id=100033&format=png" width="20">
      </div>

      <div class="modal-body modal-pengajuan">
        <div class="table-inline">
          <table>
            <tr>
              <td style="width: 100px">Tanggal</td>
              <td style="width: 4px">:</td>
              <td>{{ moment(selected.tgl_pengajuan).format("DD-MM-YYYY") }}</td>
            </tr>
            <tr>
              <td>Kebutuhan</td>
              <td>:</td>
              <td>{{ selected.kebutuhan }}</td>
            </tr>
            <tr>
              <td>Jumlah</td>
              <td>:</td>
              <td>{{ selected.jumlah | number }}</td>
            </tr>
            <tr>
              <td>Keterangan</td>
              <td>:</td>
              <td>{{ selected.keterangan || "-" }}</td>
            </tr>
            <tr>
              <td colspan="3" align="center">
                <img :src="selected.bukti" width="60%" />
              </td>
            </tr>
          </table>
        </div>
      </div>

      <div class="btn-next" @click="next()">
        <img src="https://img.icons8.com/?size=30&id=98967&format=png" width="20">
      </div>
    </div>

    <div class="modal" @click="closeModal" v-show="modalOpen">
      <div class="modal-body">
        <h3 style="margin-top: 0;">Upload Bukti Transfer</h3>

        <form class="table-inline" @submit.prevent="realisasi()">
          <table style="width: 100%;">
            <tr>
              <td style="width: 140px;">Perusahaan</td>
              <td>:</td>
              <td>
                <b>{{ data.perusahaan }}</b>
              </td>
            </tr>
            <tr>
              <td>Pemilik Rekening</td>
              <td>:</td>
              <td>
                <b>{{ data.pemilik_rekening }}</b>
              </td>
            </tr>
            <tr>
              <td>Bank</td>
              <td>:</td>
              <td>
                <b>{{ data.bank }}</b>
              </td>
            </tr>
            <tr>
              <td>No Rekening</td>
              <td>:</td>
              <td>
                <b>{{ data.no_rekening }}</b>
              </td>
            </tr>
            <tr>
              <td>Jumlah</td>
              <td>:</td>
              <td>
                <b>{{ data.pembulatan | number }}</b>
              </td>
            </tr>
            <tr>
              <td valign="top">Bukti Transfer</td>
              <td valign="top">:</td>
              <td>
                <div class="bukti-transfer" contenteditable="true" ref="buktiTransfer"></div>
                <!-- <textarea v-model="data.bukti_transfer" rows="10" style="width: calc(100% - 24px);"></textarea> -->
              </td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td>
                <button type="submit" class="btn btn-green" :disabled="loadingRealisasi" style="width: 100%">
                  REALISASI
                </button>
              </td>
            </tr>
          </table>
        </form>
      </div>
    </div>

    <div class="modal" @click="closeModal" v-show="lihatBukti">
      <div class="modal-body">
        <p style="margin-top: 0;">
          <b>Bukti Transfer Realisasi</b>
        </p>
        <div class="table-inline">
          <table>
            <tr>
              <td>Bank</td>
              <td>:</td>
              <td>{{ data.bank }}</td>
            </tr>
            <tr>
              <td>No Rekening</td>
              <td>:</td>
              <td>{{ data.no_rekening }}</td>
            </tr>
            <tr>
              <td>Pemilik Rekening</td>
              <td>:</td>
              <td>{{ data.pemilik_rekening }}</td>
            </tr>
            <tr>
              <td>Bukti Transfer</td>
              <td>:</td>
              <td>
              </td>
            </tr>
          </table>
          <div style="text-align: center;">
            <img :src="data.bukti" width="300px">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

import { apiGet, apiPost, apiUpload } from "../api";

export default {
  name: "RealisasiForm",
  components: {
    DateRangePicker,
  },
  data() {
    return {
      loading: false,
      loadingRealisasi: false,

      dataPengajuan: [],

      data: {
        id: this.$route.params.id,
      },
      selectedFile: null,
      date: {
        startDate: moment().format("YYYY-MM-DD"),
      },

      selected: {},
      selectedIndex: -1,
      modalOpen: false,
      action: null,

      lihatBukti: false,
    };
  },
  computed: {
    totalPengajuan() {
      let total = 0;
      this.dataPengajuan.forEach((item) => {
        total += parseInt(item.jumlah);
      });

      return total;
    },
    totalDirealisasi() {
      let total = 0;
      this.dataPengajuan.forEach((item) => {
        const realisasi = item.realisasi.toString().replace(/\./g, "");
        total += parseInt(realisasi);
      });

      return total;
    },
    kekurangan() {
      const jumlah =
        parseInt(this.totalDirealisasi) -
        parseInt(
          (this.data.saldo_kemarin || "0").toString().replace(/\./g, "")
        );

      return jumlah;
    },
    totalRealisasi() {
      return (
        parseInt(this.kekurangan) +
        parseInt((this.data.petty_cash || "0").toString().replace(/\./g, ""))
      );
    },
    pembulatan() {
      const pembulatan = 50000;
      const selisih = this.totalRealisasi % pembulatan;
      const dasar = this.totalRealisasi - selisih;
      const hasil = dasar % 100000;

      if (this.totalRealisasi % pembulatan == 0) {
        return this.totalRealisasi;
      } else {
        if (hasil < pembulatan) {
          return dasar;
        } else {
          return dasar + pembulatan;
        }
      }
    },
  },
  methods: {
    moment,
    setSelected(index) {
      this.selected = this.dataPengajuan[index];
      this.selectedIndex = index;
    },
    prev() {
      if (this.selectedIndex > 0) {
        this.selectedIndex--;
        this.selected = this.dataPengajuan[this.selectedIndex];
      }
    },
    next() {
      if (this.selectedIndex < this.dataPengajuan.length - 1) {
        this.selectedIndex++;
        this.selected = this.dataPengajuan[this.selectedIndex];
      }
    },
    closeModal(e) {
      if (e.target.classList[0] == "modal") {
        this.selected = {};
        this.modalOpen = false;
        this.lihatBukti = false;
      }
    },
    openModalRealisasi() {
      this.modalOpen = true;
    },
    onFileChange(event) {
      this.selectedFile = event.target.files[0];

      const maxFileSizeMB = 2;
      const fileSizeMB = this.selectedFile.size / (1024 * 1024);

      if (fileSizeMB > maxFileSizeMB) {
        alert(
          `Ukuran file terlalu besar. Ukuran maksimum adalah ${maxFileSizeMB} MB.`
        );
        return;
      }
    },
    async load() {
      this.$loading(true);

      const res = await apiGet(`/v1/realisasi?id=${this.data.id}`);

      setTimeout(() => {
        this.data = res.data;
        this.data.petty_cash = 500000;
        this.data.saldo_kemarin = res.data.saldo_kemarin || 0;

        this.dataPengajuan = this.data.detail;
        this.dataPengajuan.forEach((item) => {
          // kalo udah ada total_direalisasi berarti udah divalidasi, jadi ambil data yang sudah divalidasi
          item.realisasi = this.data.total_direalisasi
            ? this.$options.filters.number(item.realisasi)
            : this.$options.filters.number(item.jumlah);
        });

        if (!this.data.saldo_kemarin) {
          this.getSaldo(this.data.id_perusahaan);
        }

        this.$loading(false);
      }, 150);
    },

    objectToFormData(obj) {
      const formData = new FormData();

      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          formData.append(key, obj[key]);
        }
      }
      return formData;
    },

    async validasi() {
      if (
        confirm(
          this.action == "realisasi" ? "Realisasi Pengajuan?" : "Validasi Data?"
        )
      ) {

        if (this.action == 'realisasi') {
          this.loadingRealisasi = true;
        }

        this.data.action =
          this.action == "realisasi" ? "realisasi" : "validasi";
        this.data.tgl_realisasi = moment(this.date.startDate).format(
          "YYYY-MM-DD"
        );

        this.data.total_pengajuan = this.totalPengajuan;
        this.data.total_direalisasi = this.totalDirealisasi;
        this.data.kekurangan = this.kekurangan;
        this.data.total_realisasi = this.totalRealisasi;
        this.data.pembulatan = this.pembulatan;
        this.data.saldo_kemarin = parseInt(
          this.data.saldo_kemarin.toString().replace(/\./g, "")
        );
        this.data.petty_cash = parseInt(
          this.data.petty_cash.toString().replace(/\./g, "")
        );

        const detail = [];
        this.dataPengajuan.forEach((item) => {
          detail.push({
            id_pengajuan: item.id,
            realisasi: parseInt(item.realisasi.toString().replace(/\./g, "")),
            keterangan: item.keterangan,
          });
        });
        this.data.detail = JSON.stringify(detail);

        // Convert to form data
        let formData = this.objectToFormData(this.data);
        if (this.selectedFile) {
          formData.append("file", this.selectedFile);
        }

        this.$loading(true);
        await apiUpload(`/v1/realisasi/save`, formData);
        this.$loading(false);

        location.href = "/realisasi";
      }

      // if (this.action == "realisasi") {
      //   await apiPost(`/v1/realisasi`, this.data);
      //   this.actiono = null;
      // } else {
      // }
    },

    realisasi() {
      this.action = "realisasi";

      const bukti = this.$refs.buktiTransfer.innerHTML || '';
      this.data.bukti = bukti;

      this.validasi();
    },

    async getSaldo(id_perusahaan) {
      const res = await apiGet(
        `/v1/saldo?id_perusahaan=${id_perusahaan}`
      );

      if (res.data) {
        this.data.saldo_kemarin = res.data.saldo;
      }
    },
  },
  mounted() {
    this.$store.commit("setting/setActivePage", "realisasi");

    this.load();
  },
};
</script>

<style lang="scss" scoped>
h1 {
  display: flex;
  align-items: center;

  span {
    margin-right: 10px;
    cursor: pointer;
  }
}

.realisasi-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;


  .sudah-realisasi {
    margin: 0;
    background: var(--green);
    color: #fff;
    padding: 10px 20px;
    border-radius: 6px;
    font-size: 13px;
    text-align: center;

    span {
      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}

.content {
  .table-inline {
    table {
      width: 420px !important;
    }

    .table-wrapper {
      margin-top: 10px;
      margin-bottom: 20px;

      height: calc(100vh - 220px);
      overflow: auto;

      table {
        width: 100% !important;
        margin-bottom: 20px;
      }
    }

    input,
    textarea {
      width: calc(100% - 22px);
    }

    select {
      width: 100%;
    }

    table {
      width: 100%;

      tr {
        td {
          &:nth-child(1) {
            width: 100px;
          }

          &:nth-child(2) {
            width: 10px;
          }

          &.btn-group {
            display: flex;
            gap: 10px;
          }
        }

        &.separator {
          td {
            border-top: 2px solid #ccc;
          }
        }

        &:hover {
          td {
            background: none;
            cursor: default;
          }
        }
      }

      tfoot {
        tr {
          td {
            border-bottom: 0;
          }
        }
      }
    }

    button {
      width: 150px;
    }
  }

  .btn-group {
    display: flex;
    gap: 10px;
  }
}

.modal {
  gap: 10px;
}

.modal-body.modal-pengajuan {
  width: 700px;
  height: 70vh !important;

  h3 {
    margin-top: 0;
  }
}

.btn-prev,
.btn-next {
  left: -30px;
  width: 30px;
  height: 30px;
  border-radius: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  margin-top: calc(70vh / 2 + 50px);
  padding: 6px;
  cursor: pointer;
}

.btn-bukti {
  color: var(--blue);
  padding: 4px 8px;
  border-radius: 6px;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    background: var(--blue-hover);
    color: #fff;
  }
}

.bukti-transfer {
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 10px;
  width: calc(100% - 12px);
  height: 100px;
  outline: none;

  &:focus {
    border: 1px solid var(--blue);
  }
}
</style>