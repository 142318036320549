<template>
  <div class="home">
    <h1>Input Pengajuan</h1>

    <div class="content">
      <div class="form-group" style="width: 220px;">
        <label>Periode Pengajuan</label>
        <date-range-picker v-model="param.periodePengajuan" :auto-apply="true" :ranges="false">
          <template v-slot:input="picker">
            {{ moment(picker.startDate).format("DD MMM YYYY") }} -
            {{ moment(picker.endDate).format("DD MMM YYYY") }}
          </template>
        </date-range-picker>
      </div>
      <div class="form-group">
        <button type="button" class="btn btn-outline" @click="openForm()">Tambah Pengajuan</button>
      </div>

      <div class="table-wrapper">
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>Tanggal</th>
              <th>Kebutuhan</th>
              <th style="text-align: right">Jumlah</th>
              <th style="text-align: right">Realisasi</th>
              <th>Keterangan</th>
              <th>Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in data" :key="index" @click="openForm(item)">
              <td v-if="item.group" colspan="8" style="background: #f7f7f7;text-transform: uppercase;">
                <b>{{ item.perusahaan }}</b>
              </td>

              <td v-if="!item.group">{{ ++index }}</td>
              <td v-if="!item.group">{{ moment(item.tgl_pengajuan).format("DD/MM/YYYY") }}</td>
              <td v-if="!item.group">{{ item.kebutuhan }}</td>
              <td v-if="!item.group" align="right">{{ item.jumlah | number }}</td>
              <td v-if="!item.group" align="right">
                {{ item.is_realisasi !== 1 ? 0 : item.realisasi | number }}
              </td>
              <td v-if="!item.group">{{ item.keterangan || "-" }}</td>
              <td v-if="!item.group">
                <span :class="item.is_realisasi ? 'realisasi' : (item.pembulatan ? 'validasi' : 'pending')">
                  {{ item.is_realisasi ? 'Sudah Realisasi' : (item.pembulatan ? 'Sudah Validasi' : 'Belum Validasi')
                  }}
                </span>
              </td>
              <td v-if="!item.group" align="right">
                <span class="btn-hapus" @click.stop="hapus(item)" v-if="item.is_realisasi != 1">Hapus</span>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td colspan="3"><b>TOTAL</b></td>
              <td align="right"><b>{{ totalPengajuan | number }}</b></td>
              <td align="right"><b>{{ totalRealisasi | number }}</b></td>
            </tr>
          </tfoot>
        </table>

        <div class="loading" v-show="loading">
          <div class="dot-loader"></div>
        </div>

        <p style="text-align: center; padding: 120px 0" v-show="data.length <= 0 && !loading">
          Tidak ada data
        </p>
      </div>

      <div style="display: flex;gap: 10px;">
        <button type="button" class="btn" style="padding-left: 30px;padding-right: 30px;"
          @click="simpanPengajuan()">SIMPAN
          PENGAJUAN</button>
        <button type="button" class="btn btn-outline" @click="$router.push('/pengajuan')"
          style="width: 140px;">Kembali</button>
      </div>
    </div>

    <div class="modal" v-if="modalOpen" @click="closeModal">
      <div class="modal-body">
        <PengajuanForm @reload="load()" @dismiss="forceCloseModal" :pengajuan="param" :selectedData="selectedData" />
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import _ from "lodash";

import { apiGet, apiPost } from "../api";

import PengajuanForm from "./PengajuanForm.vue";

import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

export default {
  name: "Order",
  components: {
    PengajuanForm, DateRangePicker
  },
  data() {
    return {
      param: {
        id: this.$route.params.id,
        periodePengajuan: {
          startDate: moment().subtract(1, 'weeks').day(6).format("YYYY-MM-DD"),
          endDate: moment().day(5).format("YYYY-MM-DD"),
        },
      },
      modalOpen: false,
      data: [],
      selectedData: {
        id: -1
      },

      totalPengajuan: 0,
      totalRealisasi: 0,

      loading: false,
    };
  },
  methods: {
    moment,
    openForm(item) {
      this.selectedData = {
        id: -1,
      };
      if (item) {
        this.selectedData = item;
      }
      this.modalOpen = true;
    },
    closeModal(e) {
      if (e.target.classList[0] == "modal") {
        this.modalOpen = false;
      }
    },
    forceCloseModal() {
      this.modalOpen = false;
    },
    async load() {
      this.loading = true;

      const filter = {
        id_pengajuan: this.param.id,
      };

      if (this.param.id == -1) {
        filter.id_perusahaan = this.$store.state.setting.login.id_perusahaan;
      }
      const res = await apiGet(`/v1/pengajuan/detail?${this.$objectToQuery(filter)}`);

      setTimeout(() => {
        this.data = res.data;

        if (res.pengajuan) {
          this.param.periodePengajuan = {
            startDate: moment(res.pengajuan.tgl_periode_awal).format("YYYY-MM-DD"),
            endDate: moment(res.pengajuan.tgl_periode_akhir).format("YYYY-MM-DD"),
          };
        }

        this.totalPengajuan = 0;
        this.totalRealisasi = 0;

        this.data.forEach((item) => {
          this.totalPengajuan += parseInt(item.jumlah);
          this.totalRealisasi += parseInt(item.realisasi);
        });

        if (this.$store.state.setting.login.akses == "HEAD OFFICE") {
          const group = _.groupBy(this.data, "perusahaan");

          this.data = [];
          Object.keys(group).forEach((perusahaan) => {
            this.data.push({
              group: true,
              perusahaan,
            });

            group[perusahaan].forEach((item) => {
              this.data.push(item);
            });
          });
        }

        this.loading = false;
      }, 150);
      this.$loading(false);
    },
    async hapus(item) {
      if (confirm("Hapus?")) {
        const res = await apiPost(`/v1/pengajuan/delete`, { id: item.id });
        if (res.valid) {
          this.load();
        }
      }
    },
    async simpanPengajuan() {
      if (confirm('Pastikan tanggal periode pengajuan sudah sesuai')) {
        this.param.tgl_periode_awal = this.param.periodePengajuan.startDate
        this.param.tgl_periode_akhir = this.param.periodePengajuan.endDate
        this.param.total_pengajuan = this.totalPengajuan;

        const res = await apiPost(`/v1/pengajuan/save`, this.param)
        if (res.valid) {
          this.$toast("Pengajuan Berhasil disimpan");
          this.$router.push('/pengajuan')
        }
      }
    }
  },
  mounted() {
    this.$store.commit("setting/setActivePage", "pengajuan");
    this.$loading(false);

    this.load();
  },
};
</script>

<style lang="scss" scoped>
.table-wrapper {
  height: calc(100vh - 190px - 100px);
  margin-bottom: 15px;

  th,
  td {
    white-space: nowrap;
  }

  span {
    font-size: 12px;
    padding: 4px 8px;
    border-radius: 6px;

    &.pending {
      background: var(--orange);
      color: #fff;
    }

    &.validasi {
      background: var(--blue);
      color: #fff;
    }

    &.realisasi {
      background: var(--green);
      color: #fff;
    }
  }
}
</style>