<template>
  <div class="home">
    <h1>Pembayaran Siswa</h1>

    <div class="content">
      <div class="top-bar">
        <div>
          <router-link class="btn" to="/pembayaran-siswa/-1">
            INPUT PEMBAYARAN
          </router-link>
        </div>
        <div class="filter">
          <form>
            <button
              type="button"
              class="btn btn-outline"
              @click="showFilter = true"
            >
              FILTER 
            </button>
          </form>
        </div>
      </div>

      <div
        class="filter-overlay"
        v-show="showFilter"
        @click="closeFilter"
      ></div>
      <div class="filter-content" :class="{ active: showFilter }">
        <form @submit.prevent="load(true)">
          <h2>
            Filter Data
            <img
              src="https://img.icons8.com/?size=48&id=82764&format=png"
              height="24"
              @click="closeFilter()"
            />
          </h2>
          <div class="form-group">
            <label>No Daftar</label>
            <input type="text" v-model="filter.no_daftar" />
          </div>
          <div class="form-group">
            <label>Nama Siswa</label>
            <input type="text" v-model="filter.nama" />
          </div>
          <div class="form-group">
            <label>Kampus</label>
            <select v-model="filter.id_kampus">
              <option value="1">PSPP Lampung</option>
              <option value="2">PSPP Jakarta</option>
              <option value="3">PSPP Yogyakarta</option>
              <option value="5">PSPP Makassar</option>
              <option value="6">PSPP Bali</option>
            </select>
          </div>
          <div class="form-group">
            <label>PIC Kampus</label>
            <select v-model="filter.id_user">
              <option
                v-for="(item, index) in paramPicKampus"
                :key="index"
                :value="item.id_user"
              >
                {{ item.nama }}
              </option>
            </select>
          </div>
          <div class="form-group">
            <label>Bulan</label>
            <select v-model="filter.bulan">
              <option
                v-for="(item, index) in paramBulan"
                :key="index"
                :value="String(index).padStart(2, '0')"
              >
                {{ item }}
              </option>
            </select>
          </div>
          <div class="form-group">
            <label>Minggu</label>
            <select v-model="filter.minggu">
              <option
                v-for="(item, index) in paramMinggu"
                :key="index"
                :value="index"
              >
                {{ item }}
              </option>
            </select>
          </div>
          <div class="form-group">
            <label>Status</label>
            <select v-model="filter.status">
              <option value="PENDING">Belum Validasi</option>
              <option value="VALIDASI">Sudah Validasi</option>
            </select>
          </div>
          <div class="btn-group">
            <button type="submit" class="btn btn-primary">FILTER DATA</button>
            <button type="button" class="btn btn-outline" @click="resetFilter()">RESET</button>
          </div>
        </form>
      </div>

      <div class="filter-status" v-show="submitFilter">
        <span v-show="filter.no_daftar">No Daftar: {{ filter.no_daftar }}</span>
        <span v-show="filter.nama">Kampus: {{ filter.nama}}</span>
        <span v-show="filter.id_kampus">Kampus: {{ filter.id_kampus}}</span>
        <span v-show="filter.id_user">PIC Kampus: {{ filter.id_user}}</span>
        <span v-show="filter.bulan">Bulan: {{ filter.bulan}}</span>
        <span v-show="filter.minggu">Minggu: Minggu ke-{{ filter.minggu}}</span>
        <span v-show="filter.status">Status: {{ filter.status}}</span>
      </div>

      <div class="table-wrapper" :class="{ 'filter-active': submitFilter }">
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>Tgl Bayar</th>
              <th>Angkatan</th>
              <th>Kampus</th>
              <th>Jurusan</th>
              <th>No Daftar</th>
              <th>Siswa</th>
              <th style="text-align: right">Jumlah</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in data"
              :key="index"
              :class="{
                ditolak: item.status == 'DITOLAK',
                validasi: item.status == 'VALIDASI',
              }"
              @click="
                $to(
                  `/pembayaran-siswa/${item.id}?id_siswa=${item.id_siswa}&no_daftar=${item.no_daftar}`
                )
              "
            >
              <td>{{ ++index }}</td>
              <td>
                {{ moment(item.tgl_bayar).format("DD/MM/YYYY") }}
              </td>
              <td>{{ item.angkatan }}</td>
              <td>{{ item.kampus }}</td>
              <td>{{ item.jurusan }}</td>
              <td>{{ item.no_daftar }}</td>
              <td>{{ item.nama_siswa }}</td>
              <td align="right">{{ item.jumlah_fix | number }}</td>
            </tr>
          </tbody>
        </table>

        <p
          style="text-align: center; padding: 100px 0"
          v-show="data.length <= 0 && !loading"
        >
          Tidak ada data
        </p>
      </div>
    </div>

    <div class="modal" v-if="$route.params.id" @click="closeModal">
      <div class="modal-body">
        <PembayaranSiswaForm @reload="load()" />
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

import { apiGet, apiPost } from "../api";

import PembayaranSiswaForm from "./PembayaranSiswaForm.vue";

export default {
  name: "PembayaranSiswa",
  components: {
    PembayaranSiswaForm,
  },
  data() {
    return {
      modal: false,
      showFilter: false,
      submitFilter: false,

      data: [],
      dataPerusahaan: [],

      filter: {
        status: 'PENDING'
      },

      paramBulan: [
        "",
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ],
      paramMinggu: [
        "",
        "Minggu ke-1",
        "Minggu ke-2",
        "Minggu ke-3",
        "Minggu ke-4",
        "Minggu ke-5",
      ],
      paramPicKampus: [],
    };
  },
  methods: {
    moment,
    openModal(val) {
      this.modal = val;
    },
    closeModal(e) {
      if (e.target.classList[0] == "modal") {
        this.$router.back();
      }
    },
    closeFilter() {
      this.showFilter = false;
    },
    async loadPerusahaan() {
      this.$loading(true);

      const res = await apiGet(`/v1/perusahaan`);
      this.dataPerusahaan = res.data;
    },
    async load(submitFilter) {
      this.$loading(true);
      const filter = this.$objectToQuery(this.filter);

      const res = await apiGet(`/v1/pembayaran-siswa?${filter}`);

      setTimeout(() => {
        this.data = res.data;
        this.submitFilter = submitFilter;

        this.closeFilter();
        this.$loading(false);
      }, 150);
    },
    async hapus(item) {
      if (confirm("Hapus?")) {
        const res = await apiPost(`/v1/pengajuan/delete`, { id: item.id });
        if (res.valid) {
          this.load();
        }
      }
    },
    async loadPicKampus() {
      const res = await apiGet(`/v1/user/pic-kampus`);
      this.paramPicKampus = res.data;
    },
    resetFilter() {
      this.filter = {
        status: 'PENDING'
      }
      this.load(false);
    }
  },
  mounted() {
    this.$store.commit("setting/setActivePage", "pembayaran-siswa");

    this.load(false);
    this.loadPicKampus();
  },
};
</script>

<style lang="scss" scoped>
.top-bar {
  margin-top: 45px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;

  a {
    width: 150px;
    text-align: center;
  }

  .filter {
    position: relative;

    p {
      position: absolute;
      font-size: 14px;
      top: -40px;
    }

    form {
      .btn.btn-outline {
        width: 120px;
      }
    }
  }
}

.table-wrapper {
  height: calc(100vh - 190px);

  &.filter-active {
    height: calc(100vh - 190px - 40px);
  }
}

.ditolak {
  color: #f00;
}
.validasi {
  color: var(--green-darker);
}
</style>