import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from '../views/Login.vue';
import Home from '../views/Home.vue';

import Pengajuan from '../views/Pengajuan.vue';
import PengajuanDetail from '../views/PengajuanDetail.vue';
import Transaksi from '../views/Transaksi.vue';

import Realisasi from '../views/Realisasi.vue';
import RealisasiForm from '../views/RealisasiForm.vue';

import PembayaranSiswa from '../views/PembayaranSiswa';
import PembayaranSiswaForm from '../views/PembayaranSiswaForm';
import Refund from '../views/Refund.vue';
import RefundForm from '../views/RefundForm.vue';

import Fmi from '../views/Fmi';
import LaporanPembayaran from '../views/LaporanPembayaran';

import Pos from '../views/Pos.vue';
import ResumeTransaksi from '../views/ResumeTransaksi.vue';
import ResumeRealisasi from '../views/ResumeRealisasi.vue';
import Laporan from '../views/Laporan.vue';

import Perusahaan from '../views/Perusahaan.vue';
import PerusahaanForm from '../views/PerusahaanForm.vue';
import User from '../views/User.vue';
import Rekening from '../views/Rekening.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
  },

  {
    path: '/pengajuan',
    name: 'Pengajuan',
    component: Pengajuan,
  },
  {
    path: '/pengajuan/:id',
    name: 'PengajuanDetail',
    component: PengajuanDetail,
  },
  // {
  //   path: '/pengajuan/:id',
  //   name: 'Pengajuan',
  //   component: Pengajuan,
  // },

  {
    path: '/realisasi',
    name: 'Realisasi',
    component: Realisasi,
  },
  {
    path: '/realisasi/:id',
    name: 'RealisasiForm',
    component: RealisasiForm,
  },
  {
    path: '/realisasi/:id',
    name: 'RealisasiForm',
    component: RealisasiForm,
  },

  {
    path: '/transaksi',
    name: 'Transaksi',
    component: Transaksi,
  },
  {
    path: '/transaksi/:id',
    name: 'Transaksi',
    component: Transaksi,
  },

  {
    path: '/pembayaran-siswa',
    name: 'PembayaranSiswa',
    component: PembayaranSiswa,
  },
  {
    path: '/pembayaran-siswa/:id',
    name: 'PembayaranSiswaForm',
    component: PembayaranSiswaForm,
  },
  {
    path: '/refund',
    name: 'Refund',
    component: Refund,
  },
  {
    path: '/refund/:id',
    name: 'RefundForm',
    component: RefundForm,
  },
  {
    path: '/fmi',
    name: 'Fmi',
    component: Fmi,
  },
  {
    path: '/laporan-pembayaran',
    name: 'LaporanPembayaran',
    component: LaporanPembayaran,
  },

  {
    path: '/resume/realisasi',
    name: 'ResumeRealisasi',
    component: ResumeRealisasi,
  },
  {
    path: '/resume/transaksi',
    name: 'ResumeTransaksi',
    component: ResumeTransaksi,
  },

  {
    path: '/pos/',
    name: 'Pos',
    component: Pos,
  },
  {
    path: '/pos/:id',
    name: 'Pos',
    component: Pos,
  },
  {
    path: '/laporan',
    name: 'Laporan',
    component: Laporan,
  },

  {
    path: '/perusahaan',
    name: 'Perusahaan',
    component: Perusahaan,
  },
  {
    path: '/perusahaan/:id',
    name: 'PerusahaanForm',
    component: PerusahaanForm,
  },
  {
    path: '/user',
    name: 'User',
    component: User,
  },
  {
    path: '/user/:id',
    name: 'User',
    component: User,
  },
  {
    path: '/rekening',
    name: 'Rekening',
    component: Rekening,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
