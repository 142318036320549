<template>
  <div class="home">
    <h1>Home</h1>

    <div class="content">
      <div class="grid">
        <div class="card">
          <h1>{{ data.totalRealisasi | number }}</h1>
          <p>Realisasi bulan ini</p>
        </div>
        <div class="card">
          <h1>{{ data.totalTransaksi | number }}</h1>
          <p>Transaksi bulan ini</p>
        </div>

        <div class="card">
          <h1>{{ data.totalPembayaranSiswa | number }}</h1>
          <p>Pembayaran siswa bulan ini</p>
        </div>
        <div class="card">
          <h1>{{ data.totalFmi | number }}</h1>
          <p>FMI bulan ini</p>
        </div>
      </div>

      <div class="grid grid-2">
        <div class="card">
          <h3>Pengajuan Terbaru</h3>
          <div class="table-wrapper">
            <table>
              <thead>
                <tr>
                  <th>No</th>
                  <th>Perusahaan</th>
                  <th style="text-align: right;">Jumlah</th>
                  <th style="text-align: center;">Status</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item, index in data.pengajuanTerbaru" :key="index">
                  <td>{{ ++index }}</td>
                  <td>{{ item.perusahaan }}</td>
                  <td align="right">{{ item.total_pengajuan | number }}</td>
                  <td align="center">{{ !item.total_realisasi ? 'Perlu Divalidasi' : 'Sudah Divalidasi' }}</td>
                </tr>
              </tbody>
            </table>

            <p class="mt" v-show="data.pengajuanTerbaru.length <= 0">
              Tidak ada data
            </p>
          </div>
        </div>
        <div class="card">
          <h3>Realisasi Terbaru</h3>
          <div class="table-wrapper">
            <table>
              <thead>
                <tr>
                  <th>No</th>
                  <th>Perusahaan</th>
                  <th style="text-align: right;">Jumlah</th>
                  <th style="text-align: center;">Tgl Realisasi</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item, index in data.realisasiTerbaru" :key="index">
                  <td>{{ ++index }}</td>
                  <td>{{ item.perusahaan }}</td>
                  <td align="right">{{ item.total_pengajuan | number }}</td>
                  <td align="center">
                    {{ moment(item.tgl_realisasi).format('DD/MM/YYYY') }}
                  </td>
                </tr>
              </tbody>
            </table>

            <p class="mt" v-show="data.realisasiTerbaru.length <= 0">
              Tidak ada data
            </p>
          </div>
        </div>
      </div>

      <div class="chart" v-if="false">
        <div class="chart-title">
          <h3>Grapik</h3>

          <div></div>
        </div>

        <div class="chart-body">

          <div class="chart-amt">

          </div>
          <div class="chart-bar">

            <div class="bar">
              <div class="wrapper">
                <div class="pemasukan"></div>
                <div class="pengeluaran"></div>
              </div>
              <p>Januari</p>
            </div>

            <div class="bar">
              <div class="wrapper">
                <div class="pemasukan"></div>
                <div class="pengeluaran"></div>
              </div>
              <p>Februari</p>
            </div>

          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { apiGet } from '@/api';
import moment from 'moment';

export default {
  name: "Home",
  data() {
    return {
      data: {
        totalRealisasi: 0,
        totalTransaksi: 0,
        totalPembayaranSiswa: 0,
        totalFmi: 0,

        pengajuanTerbaru: [],
        realisasiTerbaru: [],
      }
    }
  },
  methods: {
    moment,
    async loadTotalRealisasi() {
      const res = await apiGet('/v1/dashboard/total-realisasi')
      this.data.totalRealisasi = res.data;
    },
    async loadTotalTransaksi() {
      const res = await apiGet('/v1/dashboard/total-transaksi')
      this.data.totalTransaksi = res.data;
    },
    async loadTotalPembayaranSiswa() {
      const res = await apiGet('/v1/dashboard/total-pembayaran-siswa')
      this.data.totalPembayaranSiswa = res.data.total_pembayaran_siswa;
      this.data.totalFmi = res.data.total_fmi;
    },
    async loadPengajuanTerbaru() {
      const res = await apiGet('/v1/dashboard/pengajuan-terbaru')
      this.data.pengajuanTerbaru = res.data;
    },
    async loadRealisasiTerbaru() {
      const res = await apiGet('/v1/dashboard/realisasi-terbaru')
      this.data.realisasiTerbaru = res.data;
    }
  },
  mounted() {
    this.$store.commit("setting/setActivePage", "home");

    this.loadTotalRealisasi();
    this.loadTotalTransaksi();
    this.loadTotalPembayaranSiswa();

    this.loadPengajuanTerbaru();
    this.loadRealisasiTerbaru();
  },
};
</script>

<style lang="scss" scoped>
.grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 30px;
  margin-bottom: 30px;

  &.grid-2 {
    grid-template-columns: repeat(2, 1fr);

    .card {
      height: 400px;
      overflow-y: auto;

      h3 {
        margin-top: 0;
      }

      table {
        width: 100%;
      }

      .mt {
        text-align: center;
        padding: 100px 0;
      }
    }
  }
}

.content {
  margin-top: 40px;
  padding-bottom: 20px;

  height: calc(100vh - 150px);
  overflow-y: auto;
}

.card {

  h1,
  p {
    margin: 0;
  }

  p {
    color: #777;
    margin-top: 15px;
  }
}

.chart {
  height: 500px;
  background: #fff;
  border-radius: 15px;
  margin-top: 30px;
  padding: 20px;
  display: flex;
  flex-direction: column;

  .chart-title {
    h3 {
      margin-top: 0;
    }
  }


  .chart-body {
    flex: 1;
    display: grid;
    grid-template-columns: 20px 1fr;
    position: relative;
    height: 100%;

    .chart-bar {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 100%;

      display: grid;
      grid-template-columns: repeat(12, 1fr);
      gap: 20px;

      .bar {
        position: relative;
        display: flex;
        flex-direction: column;

        p {
          margin-bottom: 0;
        }

        .wrapper {
          flex: 1;
          display: flex;
          justify-content: space-around;
          align-items: flex-end;
          gap: 8px;

          >div {
            width: 40px;
            border-radius: 10px;
          }
        }

        .pemasukan {
          height: 100%;
          background-color: var(--blue);
        }

        .pengeluaran {
          height: 20%;
          background-color: #95E0FB;
        }
      }
    }
  }
}
</style>