<template>
  <div class="home">
    <h1>Pengajuan</h1>

    <div class="content">
      <div class="top-bar">
        <div>
          <router-link class="btn" to="/pengajuan/-1">
            INPUT PENGAJUAN
          </router-link>
        </div>
        <div class="filter">
          <form>
            <button type="button" class="btn btn-outline" @click="showFilter = true">FILTER</button>
          </form>
        </div>
      </div>

      <div class="filter-overlay" v-show="showFilter" @click="closeFilter"></div>
      <div class="filter-content" :class="{ active: showFilter }">
        <form @submit.prevent="load(true)">
          <h2>
            Filter Data
            <img src="https://img.icons8.com/?size=48&id=82764&format=png" height="24" @click="closeFilter()" />
          </h2>
          <div class="form-group">
            <label>Tahun</label>
            <select v-model="filter.tahun">
              <option v-for="(item, index) in paramTahun" :key="index" :value="item">
                {{ item }}
              </option>
            </select>
          </div>
          <div class="form-group">
            <label>Bulan</label>
            <select v-model="filter.bulan">
              <option v-for="(item, index) in paramBulan" :key="index" :value="String(index).padStart(2, '0')">
                {{ item }}
              </option>
            </select>
          </div>
          <div class="form-group">
            <label>Minggu</label>
            <select v-model="filter.minggu_ke">
              <option v-for="(item, index) in paramMinggu" :key="index" :value="index">
                {{ item }}
              </option>
            </select>
          </div>
          <div class="form-group">
            <label>Status</label>
            <select v-model="filter.status">
              <option value="SEMUA">Semua</option>
              <option value="BELUM VALIDASI">Belum Validasi</option>
              <option value="SUDAH VALIDASI">Sudah Validasi</option>
              <option value="SUDAH REALISASI">Sudah Realisasi</option>
              <option value="BELUM TRANSAKSI">Belum Ditransaksikan</option>
              <option value="SUDAH TRANSAKSI">Sudah Ditransaksikan</option>
            </select>
          </div>
          <div class="form-group" v-if="$store.state.setting.login.akses == 'HEAD OFFICE'">
            <label>Perusahaan</label>
            <select v-model="filter.id_perusahaan">
              <option value="SEMUA">Semua Perusahaan</option>
              <option :value="item.id" v-for="(item, index) in dataPerusahaan" :key="index">
                {{ item.nama }}
              </option>
            </select>
          </div>
          <div class="btn-group">
            <button type="submit" class="btn btn-primary">FILTER DATA</button>
            <button type="button" class="btn btn-outline" @click="resetFilter()">RESET</button>
          </div>
        </form>
      </div>

      <div class="filter-status">
        <span v-show="filter.tahun">Tahun: {{ filter.tahun }}</span>
        <span v-show="filter.bulan">Bulan: {{ filter.bulan }}</span>
        <span v-show="filter.minggu_ke">Minggu: {{ filter.minggu_ke }}</span>
        <span v-show="filter.status">Status: {{ filter.status }}</span>
        <span v-show="filter.id_perusahaan && $store.state.setting.login.akses == 'HEAD OFFICE'">Perusahaan: {{
          filter.id_perusahaan }}</span>
      </div>

      <div class="table-wrapper">
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>Perusahaan</th>
              <th>Periode</th>
              <th style="text-align: right">Jumlah Pengajuan</th>
              <th style="text-align: right">Realisasi</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in data" :key="index" @click="$to(`/pengajuan/${item.id}`)">
              <td>{{ ++index }}</td>
              <td>
                {{ item.perusahaan }}
              </td>

              <td>
                <label v-if="item.tgl_periode_awal && item.tgl_periode_akhir">
                  {{ moment(item.tgl_periode_awal).format("DD MMM YYYY") }}
                  -
                  {{ moment(item.tgl_periode_akhir).format("DD MMM YYYY") }}
                </label>
                <label v-else>-</label>
              </td>
              <td align="right">{{ item.total_pengajuan | number }}</td>
              <td align="right">{{ (item.total_direalisasi || 0) | number }}</td>

              <td>
                <span :class="item.is_realisasi ? 'realisasi' : (item.pembulatan ? 'validasi' : 'pending')">
                  {{ item.is_realisasi ? 'Sudah Realisasi' : (item.pembulatan ? 'Sudah Validasi' : 'Belum Validasi')
                  }}
                </span>
              </td>
            </tr>
          </tbody>
        </table>

        <div class="loading" v-show="loading">
          <div class="dot-loader"></div>
        </div>

        <p style="text-align: center; padding: 120px 0" v-show="data.length <= 0 && !loading">
          Tidak ada data
        </p>
      </div>
    </div>

    <!-- <div class="modal" v-if="$route.params.id" @click="closeModal">
      <div class="modal-body">
        <PengajuanForm @reload="load()" />
      </div>
    </div> -->
  </div>
</template>

<script>
import moment from "moment";
import _ from "lodash";

import { apiGet, apiPost } from "../api";

import PengajuanForm from "./PengajuanForm.vue";

export default {
  name: "Order",
  components: {
    PengajuanForm,
  },
  data() {
    return {
      modal: false,
      showFilter: false,

      data: [],
      dataPerusahaan: [],

      filter: {
        id_perusahaan: this.$store.state.setting.login.akses == 'HEAD OFFICE' ? 'SEMUA' : this.$store.state.setting.login.id_perusahaan,
        tahun: moment().format('YYYY'),
        bulan: moment().format('MM'),
        status: this.$store.state.setting.login.akses == 'HEAD OFFICE' ? 'BELUM VALIDASI' : "SEMUA",
      },

      paramTahun: [],
      paramBulan: [
        "",
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ],
      paramMinggu: [
        "",
        "Minggu ke-1",
        "Minggu ke-2",
        "Minggu ke-3",
        "Minggu ke-4",
        "Minggu ke-5",
      ],

      loading: false,
    };
  },
  methods: {
    moment,
    openModal(val) {
      this.modal = val;
    },
    closeModal(e) {
      if (e.target.classList[0] == "modal") {
        this.$router.back();
      }
    },
    closeFilter() {
      this.showFilter = false;
    },
    async loadPerusahaan() {
      // this.$loading(true);

      const res = await apiGet(`/v1/perusahaan`);
      this.dataPerusahaan = res.data;
    },
    async load() {
      this.loading = true;

      const filter = this.$objectToQuery(this.filter);
      const res = await apiGet(`/v1/pengajuan?${filter}`);

      setTimeout(() => {
        this.data = res.data;

        this.closeFilter();

        this.loading = false;
      }, 150);
      this.$loading(false);
    },
    async hapus(item) {
      if (confirm("Hapus?")) {
        const res = await apiPost(`/v1/pengajuan/delete`, { id: item.id });
        if (res.valid) {
          this.load();
        }
      }
    },
    resetFilter() {
      this.filter = {
        id_perusahaan: this.$store.state.setting.login.akses == 'HEAD OFFICE' ? 'SEMUA' : this.$store.state.setting.login.id_perusahaan,
        tahun: moment().format('YYYY'),
        bulan: moment().format('MM'),
        status: "SEMUA",
      }

      this.load();
    },
  },
  mounted() {
    this.$store.commit("setting/setActivePage", "pengajuan");

    this.loadPerusahaan();
    this.load();

    const tahunMulai = 2024;
    for (var i = tahunMulai; i <= parseInt(moment().format('YYYY')); i++) {
      this.paramTahun.push(i);
    }

    console.log(this.filter)

    this.$loading(false);
  },
};
</script>

<style lang="scss" scoped>
.top-bar {
  margin-top: 45px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;

  a {
    width: 150px;
    text-align: center;
  }

  .filter {
    position: relative;

    p {
      position: absolute;
      font-size: 14px;
      top: -40px;
    }
  }

  form {
    .btn.btn-outline {
      width: 120px;
    }
  }
}

.table-wrapper {
  height: calc(100vh - 190px - 40px);

  th,
  td {
    white-space: nowrap;
  }

  span {
    font-size: 12px;
    padding: 4px 8px;
    border-radius: 6px;

    &.pending {
      background: var(--orange);
      color: #fff;
    }

    &.validasi {
      background: var(--blue);
      color: #fff;
    }

    &.realisasi {
      background: var(--green);
      color: #fff;
    }
  }
}
</style>