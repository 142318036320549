<template>
  <div class="home">
    <h1>Refund Pembayaran Siswa</h1>

    <div class="content">
      <div class="top-bar">
        <div>
          <router-link class="btn" to="/refund/-1">
            INPUT REFUND
          </router-link>
        </div>
        <div class="filter">
          <p>Filter</p>
          <form
            @submit.prevent="load()"
          >
            <input
              type="date"
              placeholder="Tgl Bayar"
              v-model="filter.tgl_bayar"
            />
            <select v-model="filter.is_validasi">
              <option value="-1">Belum Validasi</option>
              <option value="1">Sudah Validasi</option>
            </select>
            <button type="submit" class="btn btn-filter">FILTER</button>
          </form>
        </div>
      </div>

      <div class="table-wrapper">
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>Tgl Bayar</th>
              <th>Angkatan</th>
              <th>Kampus</th>
              <th>Jurusan</th>
              <th>No Daftar</th>
              <th>Siswa</th>
              <th style="text-align: right">Jumlah</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in data"
              :key="index"
              :class="{'ditolak' : item.status == 'DITOLAK'}"
              @click="$to(`/pembayaran-siswa/${item.id}?no_daftar=${item.no_daftar}`)"
            >
              <td>{{ ++index }}</td>
              <td>
                {{ moment(item.tgl_bayar).format('DD/MM/YYYY') }}
              </td>
              <td>{{ item.angkatan }}</td>
              <td>{{ item.kampus }}</td>
              <td>{{ item.jurusan }}</td>
              <td>{{ item.no_daftar }}</td>
              <td>{{ item.nama_siswa }}</td>
              <td align="right">{{ item.jumlah_fix | number }}</td>
            </tr>

            <tr v-show="data.length <= 0" class="no-hover">
              <td colspan="8">
                <p style="text-align: center; padding: 100px 0">
                  Tidak ada data
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="modal" v-if="$route.params.id" @click="closeModal">
      <div class="modal-body">
        <PembayaranSiswaForm @reload="load()" />
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

import { apiGet, apiPost } from "../api";

import PembayaranSiswaForm from "./PembayaranSiswaForm.vue";

export default {
  name: "PembayaranSiswa",
  components: {
    PembayaranSiswaForm
},
  data() {
    return {
      modal: false,
      data: [],
      dataPerusahaan: [],
      filter: {
        is_validasi: -1,
      },
    };
  },
  methods: {
    moment,
    openModal(val) {
      this.modal = val;
    },
    closeModal(e) {
      if (e.target.classList[0] == "modal") {
        this.$router.back();
      }
    },
    async loadPerusahaan() {
      this.$loading(true);

      const res = await apiGet(`/v1/perusahaan`);
      this.dataPerusahaan = res.data;
    },
    async load() {
      this.$loading(true);
      const filter = this.$objectToQuery(this.filter);

      const res = await apiGet(`/v1/pembayaran-siswa?${filter}`);

      setTimeout(() => {
        this.data = res.data;
        this.$loading(false);
      }, 150);
    },
    async hapus(item) {
      if (confirm("Hapus?")) {
        const res = await apiPost(`/v1/pengajuan/delete`, { id: item.id });
        if (res.valid) {
          this.load();
        }
      }
    },
  },
  mounted() {
    this.$store.commit("setting/setActivePage", "pembayaran-siswa");

    this.load();
  },
};
</script>

<style lang="scss" scoped>
.top-bar {
  margin-top: 45px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;

  a {
    width: 150px;
    text-align: center;
  }

  .filter {
    position: relative;

    p {
      position: absolute;
      font-size: 14px;
      top: -40px;
    }
  }
  form {
    display: grid;
    grid-template-columns: 200px 200px 100px;
    &.ho {
      grid-template-columns: 200px 200px 200px 100px;
    }
    grid-column-gap: 15px;

    .btn.btn-filter {
      background-color: var(--green) !important;
      border: 0 !important;
    }
  }
}
.table-wrapper {
  height: calc(100vh - 240px);
}

.ditolak {
    color: #f00;
  }

</style>