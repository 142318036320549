<template>
  <div class="home">
    <h1>Realisasi</h1>

    <div class="content">
      <div class="top-bar">
        <div style="display: flex; align-items: flex-end">
          {{
            $store.state.setting.login.akses == "HEAD OFFICE"
              ? "Pilih data pengajuan di bawah ini untuk realisasi"
              : "Data pengajuan yang sudah divalidasi / direalisasi"
          }}
        </div>
        <div class="filter">
          <p>Filter</p>
          <form @submit.prevent="load()" :class="$store.state.setting.login.akses == 'HEAD OFFICE' ? 'ho' : ''
            ">
            <!-- <input type="date" placeholder="Tgl Order" v-model="filter.tgl_order" /> -->
            <select v-model="filter.is_realisasi">
              <option value="-1">Belum Realisasi</option>
              <option value="1">Sudah Realisasi</option>
            </select>
            <select v-model="filter.id_perusahaan" v-if="$store.state.setting.login.akses == 'HEAD OFFICE'">
              <option value="SEMUA">Semua Perusahaan</option>
              <option :value="item.id" v-for="(item, index) in dataPerusahaan" :key="index">
                {{ item.nama }}
              </option>
            </select>
            <button type="submit" class="btn btn-filter">FILTER</button>
          </form>
        </div>
      </div>

      <div class="table-wrapper">
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>Perusahaan</th>
              <th>Periode</th>
              <th style="text-align: right">Pengajuan</th>
              <th style="text-align: right">Saldo Kemarin</th>
              <th style="text-align: right">Kekurangan</th>
              <th style="text-align: right">Petty Cash</th>
              <th style="text-align: right">Total Realisasi</th>
              <th style="text-align: right">Pembulatan</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in data" :key="index"
              :class="item.is_realisasi == 1 ? 'realisasi' : item.total_direalisasi ? 'validasi' : ''"
              @click="detail(item)">
              <td>{{ ++index }}</td>
              <td>{{ item.perusahaan }}</td>
              <td>{{ moment(item.tgl_periode_awal).format('DD MMM YYYY') }} - {{
                moment(item.tgl_periode_akhir).format('DD MMM YYYY') }}
              </td>
              <td align="right">{{ item.total_pengajuan | number }}</td>
              <td align="right">{{ item.saldo_kemarin || 0 | number }}</td>
              <td align="right">{{ item.kekurangan || 0 | number }}</td>
              <td align="right">{{ item.petty_cash || 0 | number }}</td>
              <td align="right">{{ item.total_realisasi || 0 | number }}</td>
              <td align="right">{{ item.pembulatan || 0 | number }}</td>
              <td>
                <span :class="item.is_realisasi ? 'realisasi' : (item.pembulatan ? 'validasi' : 'pending')">
                  {{ item.is_realisasi ? 'Sudah Realisasi' : (item.pembulatan ? 'Sudah Validasi' : 'Belum Validasi')
                  }}
                </span>
              </td>
            </tr>
          </tbody>
        </table>

        <div class="loading" v-show="loading">
          <div class="dot-loader"></div>
        </div>

        <p style="text-align: center; padding: 120px 0" v-show="data.length <= 0 && !loading">
          Tidak ada data
        </p>
      </div>
    </div>

    <div class="modal" v-show="modalOpen" @click="closeModal">
      <div class="modal-body">
        <div class="table-inline">
          <table>
            <tr>
              <td>Perusahaan</td>
              <td>:</td>
              <td>
                <b>{{ selected.perusahaan }}</b>
              </td>
            </tr>
            <tr>
              <td>Pemilik Rekening</td>
              <td>:</td>
              <td>
                <b>{{ selected.pemilik_rekening }}</b>
              </td>
            </tr>
            <tr>
              <td>Bank</td>
              <td>:</td>
              <td>
                <b>{{ selected.bank }}</b>
              </td>
            </tr>
            <tr>
              <td>No Rekening</td>
              <td>:</td>
              <td>
                <b>{{ selected.no_rekening }}</b>
              </td>
            </tr>
            <tr>
              <td valign="top">Bukti Transfer</td>
              <td valign="top">:</td>
              <td>
                <div v-html="selected.bukti" style="width: 500px;"></div>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { apiGet } from "../api";

import moment from "moment";

export default {
  name: "Order",
  data() {
    return {
      loading: false,

      data: [],
      dataPerusahaan: [],
      dataBank: {},
      selected: {},

      filter: {
        is_realisasi:
          this.$store.state.setting.login.akses == "CABANG" ? 1 : -1,
        id_perusahaan:
          this.$store.state.setting.login.akses == "CABANG"
            ? this.$store.state.setting.login.id_perusahaan
            : "SEMUA",
      },

      modalOpen: false,
    };
  },
  methods: {
    moment,
    closeModal(e) {
      if (e.target.classList[0] == "modal") {
        this.modalOpen = false;
        this.selected = {};
      }
    },
    detail(item) {
      if (this.$store.state.setting.login.akses == "CABANG") {
        this.selected = item;
        this.modalOpen = true;
      } else {
        this.$router.push(`/realisasi/${item.id}`);
      }
    },
    async loadPerusahaan() {
      const res = await apiGet(`/v1/perusahaan`);
      this.dataPerusahaan = res.data;
    },
    async load() {
      this.loading = true;

      const filter = this.$objectToQuery(this.filter);

      const res = await apiGet(`/v1/realisasi?${filter}`);

      setTimeout(() => {
        this.data = res.data;
        this.loading = false;
      }, 150);
    },
    async getBank(item) {
      const res = await apiGet(
        `/v1/user/bank?id_perusahaan=${item.id_perusahaan}`
      );

      setTimeout(() => {
        this.dataBank = res.data;
        this.dataBank.jumlah = item.pembulatan;
      }, 150);
    },
  },
  mounted() {
    this.$store.commit("setting/setActivePage", "realisasi");

    if (this.$store.state.setting.login.akses == "HEAD OFFICE") {
      this.loadPerusahaan();
    }
    this.load();

    this.$loading(false);
  },
};
</script>

<style lang="scss" scoped>
.top-bar {
  margin-top: 45px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;

  a {
    width: 150px;
    text-align: center;
  }

  .filter {
    position: relative;

    p {
      position: absolute;
      font-size: 14px;
      top: -40px;
    }
  }

  form {
    display: grid;
    grid-template-columns: 200px 100px;
    grid-column-gap: 15px;

    &.ho {
      grid-template-columns: 200px 200px 100px;
    }

    .btn.btn-filter {
      background-color: var(--green) !important;
      border: 0 !important;
    }
  }
}

.table-wrapper {
  height: calc(100vh - 190px);

  tr {
    span {
      font-size: 12px;
      padding: 4px 8px;
      border-radius: 6px;

      &.pending {
        background: var(--orange);
        color: #fff;
      }

      &.validasi {
        background: var(--blue);
        color: #fff;
      }

      &.realisasi {
        background: var(--green);
        color: #fff;
      }
    }
  }
}
</style>