const state = {
    activePage: '',
    loading: false,
    isLogin: false,
    login: {},
}

const getters = {
    baseUrl: state => state.settings || 'NotSet',
}

const mutations = {
    setActivePage(state, val) {
        state.activePage = val
    },
    setLoading(state, val) {
        state.loading = val
    },
    setLogin(state, val) {
        state.isLogin = true
        state.login = val
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
}
