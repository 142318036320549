<template>
  <div class="home">
    <h1>Pengajuan FMI</h1>

    <div class="content">
      <div class="top-bar">
        <div class="filter-value">
          <p>Filter</p>
          <div class="filter-status">
            <span v-show="filter.id_kampus">Kampus: {{ filter.id_kampus }}</span>
            <span v-show="filter.marketing">Marketing: {{ filter.marketing }}</span>
            <span v-show="filter.id_user">PIC: {{ filter.id_user }}</span>
            <span v-show="filter.bulan">Bulan: {{ filter.bulan }}</span>
            <span v-show="filter.minggu">Minggu: {{ filter.minggu }}</span>
            <span v-show="filter.status_fmi">Status: {{ filter.status_fmi }}</span>
          </div>
        </div>
        <div class="filter">
          <form>
            <button type="button" class="btn btn-outline" @click="showFilter = true">FILTER</button>
            <button type="button" class="btn btn-excel" @click="exportToExcel()">EXCEL</button>
          </form>
        </div>
      </div>

      <div class="filter-overlay" v-show="showFilter" @click="closeFilter"></div>
      <div class="filter-content" :class="{ active: showFilter }">
        <form @submit.prevent="load(true)">
          <h2>
            Filter Data
            <img src="https://img.icons8.com/?size=48&id=82764&format=png" height="24" @click="closeFilter()" />
          </h2>
          <div class="form-group">
            <label>Marketing</label>
            <select v-model="filter.marketing">
              <option value=""></option>
              <option v-for="(item, index) in paramMarketing" :key="index" :value="item.marketing">
                {{ item.marketing }}
              </option>
            </select>
            <!-- <small>Hanya menampilkan marketing yang memiliki FMI</small> -->
          </div>
          <div class="form-group">
            <label>Tgl Awal</label>
            <date-range-picker v-model="filter.tgl_awal" :single-date-picker="true" :auto-apply="true" :ranges="false">
              <template v-slot:input="picker">
                {{ moment(picker.startDate).format("DD-MM-YYYY") }}
              </template>
            </date-range-picker>
          </div>
          <div class="form-group">
            <label>Tgl Akhir</label>
            <date-range-picker v-model="filter.tgl_akhir" :single-date-picker="true" :auto-apply="true" :ranges="false">

              <template v-slot:input="picker">
                {{ moment(picker.startDate).format("DD-MM-YYYY") }}
              </template>
            </date-range-picker>
          </div>
          <div class="btn-group">
            <button type="submit" class="btn btn-primary">FILTER DATA</button>
            <button type="button" class="btn btn-outline" @click="resetFilter()">RESET</button>
          </div>
        </form>
      </div>

      <div class="table-wrapper">
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>No Daftar</th>
              <th>Siswa</th>
              <th>Marketing</th>
              <th>Angkatan</th>
              <th>Tgl Bayar</th>
              <th style="text-align: right;padding:0 20px;">Pengajuan</th>
              <th style="text-align: right;padding:0 20px;">ACC</th>
              <th style="text-align: right;padding:0 20px;">FMI 15%</th>
              <th style="text-align: right;padding:0 20px;">Potongan Wisuda</th>
              <th style="text-align: right;padding:0 20px;">Potongan</th>
              <th style="text-align: right;padding:0 20px;">FMI Diterima</th>
              <th>Keterangan</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in data" :key="index">

              <td v-show="item.separator" colspan="13" style="background: #eee;"></td>

              <td v-show="!item.separator">{{ item.no }}</td>
              <td v-show="!item.separator">{{ item.no_daftar }}</td>
              <td v-show="!item.separator">{{ item.nama_siswa }}</td>
              <td v-show="!item.separator">{{ item.marketing }}</td>
              <td v-show="!item.separator">{{ item.angkatan_2 }}</td>
              <td v-show="!item.separator">
                {{ moment(item.tgl_bayar).format("DD/MM/YYYY") }}
              </td>
              <td v-show="!item.separator" align="right">
                {{ item.jumlah | number }}
              </td>
              <td v-show="!item.separator" align="right">
                {{ item.jumlah_fix | number }}
              </td>
              <td v-show="!item.separator" align="right">
                {{ item.jumlah_fmi | number }}
              </td>
              <td v-show="!item.separator" align="right">
                {{ item.potongan_wisuda | number }}
              </td>
              <td v-show="!item.separator" align="right">
                {{ item.potongan | number }}
              </td>
              <td v-show="!item.separator" align="right">
                {{ item.jumlah_fmi_diterima | number }}
              </td>
              <td v-show="!item.separator">
                {{ item.keterangan }}
              </td>
            </tr>

            <tr v-show="data.length <= 0" class="no-hover">
              <td colspan="12">
                <p style="text-align: center; padding: 100px 0">
                  Tidak ada data
                </p>
              </td>
            </tr>
          </tbody>
          <tfoot style="position: sticky; bottom: 0">
            <tr>
              <td colspan="6">
                <b>TOTAL</b>
              </td>
              <td align="right">
                <b>{{ totalPengajuan() | number }}</b>
              </td>
              <td align="right">
                <b>{{ totalAcc() | number }}</b>
              </td>
              <td align="right">
                <b>{{ totalFmiKotor() | number }}</b>
              </td>
              <td align="right">
                <b>{{ totalPotonganWisuda() | number }}</b>
              </td>
              <td align="right">
                <b>{{ totalPotongan() | number }}</b>
              </td>
              <td align="right">
                <b>{{ totalFmiDiterima() | number }}</b>
              </td>
              <td></td>
            </tr>
          </tfoot>
        </table>
      </div>

      <button type="button" class="btn btn-primary btn-fmi" @click="realisasi()">
        REALISASI
      </button>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import moment from "moment";
import ExcelJS from 'exceljs';

import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

import { apiGet, apiPost } from "../api";

export default {
  name: "PembayaranSiswa",
  components: {},
  data() {
    return {
      modal: false,
      showFilter: false,
      submitFilter: false,

      originalData: [],
      data: [],
      dataPerusahaan: [],

      filter: {
        order: "marketing",
        is_validasi: 1,

        tgl_awal: {
          startDate: this.sabtuLalu()
        },
        tgl_akhir: {
          startDate: this.jumatIni()
        }
      },

      paramBulan: [
        "",
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ],
      paramMinggu: [
        "",
        "Minggu ke-1",
        "Minggu ke-2",
        "Minggu ke-3",
        "Minggu ke-4",
        "Minggu ke-5",
      ],
      paramPicKampus: [],
      paramMarketing: [],

      allCheck: false,
    };
  },
  components: {
    DateRangePicker,
  },
  methods: {
    moment,
    openModal(val) {
      this.modal = val;
    },
    closeModal(e) {
      if (e.target.classList[0] == "modal") {
        this.$router.back();
      }
    },
    closeFilter() {
      this.showFilter = false;
    },
    async loadPerusahaan() {
      this.$loading(true);

      const res = await apiGet(`/v1/perusahaan`);
      this.dataPerusahaan = res.data;
    },
    async load() {
      this.$loading(true);

      let filter = { ...this.filter }

      console.log(this.filter)

      filter.tgl_awal = filter.tgl_awal.startDate;
      filter.tgl_akhir = filter.tgl_akhir.startDate
      filter = this.$objectToQuery(filter);

      const res = await apiGet(`/v1/pembayaran-siswa?${filter}`);
      this.data = this.separator(res.data)

      this.closeFilter();
      this.$loading(false);
    },
    async loadPicKampus() {
      const res = await apiGet(`/v1/user/pic-kampus`);
      this.paramPicKampus = res.data;
    },
    async loadMarketing() {
      const res = await apiGet(`/v1/marketing`);
      this.paramMarketing = res.data;
    },
    resetFilter() {
      this.filter = {
        order: "marketing",
        is_validasi: 1,
      }

      this.filter.tgl_awal.startDate = this.sabtuLalu();
      this.filter.tgl_akhir.startDate = this.jumatIni();

      this.load(false);
    },

    totalPengajuan() {
      let total = 0;
      this.data.forEach((item) => (total += parseInt(item.jumlah)));
      return total;
    },
    totalAcc() {
      let total = 0;
      this.data.forEach((item) => (total += parseInt(item.jumlah_fix)));
      return total;
    },
    totalFmiKotor() {
      let total = 0;
      this.data.forEach((item) => (total += parseInt(item.jumlah_fmi || "0")));
      return total;
    },
    totalPotonganWisuda() {
      let total = 0;
      this.data.forEach(
        (item) => (total += parseInt(item.potongan_wisuda || "0"))
      );
      return total;
    },
    totalPotongan() {
      let total = 0;
      this.data.forEach((item) => (total += parseInt(item.potongan || "0")));
      return total;
    },
    totalFmiDiterima() {
      let total = 0;
      this.data.forEach(
        (item) => (total += parseInt(item.jumlah_fmi_diterima || "0"))
      );
      return total;
    },
    async realisasi() {
      if (confirm("Realisasi Semua FMI?")) {
        const data = [];

        this.data.forEach((item) => {
          data.push({
            id_pembayaran: item.id,
          });
        });

        const res = await apiPost(`/v1/fmi/realisasi`, data);
        if (res.valid) {
          this.$toast("Realisasi FMI berhasil");
          this.load();
        }
      }
    },

    separator(arr) {
      const result = [];

      let namaSebelumnya = null;
      let no = 1;
      arr.forEach((obj, idx) => {
        if (obj.marketing !== namaSebelumnya) {
          result.push({ separator: true });
          namaSebelumnya = obj.marketing;
        }

        obj.no = no++;
        result.push(obj);
      });

      return result.splice(1);
    },
    exportToExcel() {
      // Membuat workbook baru
      const workbook = new ExcelJS.Workbook();

      // Membuat worksheet baru
      const worksheet = workbook.addWorksheet('Data');

      // Menambahkan header
      worksheet.addRow(['NO', 'NAMA SISWA', 'NAMA MARKETING', 'ANGKATAN', 'TGL TRANSFER', 'ACC', 'FEE ESTAFET', '', 'KASBON', '', 'TOTAL DITERIMA', 'KETERANGAN']);
      worksheet.addRow(['', '', '', '', '', '', '%', 'JUMLAH', '%', 'JUMLAH', '', '']);

      worksheet.mergeCells('G1:H1');
      worksheet.mergeCells('I1:J1');

      worksheet.mergeCells('A1:A2');
      worksheet.mergeCells('B1:B2');
      worksheet.mergeCells('C1:C2');
      worksheet.mergeCells('D1:D2');
      worksheet.mergeCells('E1:E2');
      worksheet.mergeCells('F1:F2');
      worksheet.mergeCells('K1:K2');
      worksheet.mergeCells('L1:L2');

      // Menambahkan data
      let no = 1;
      this.data.forEach((item) => {
        if (!item.separator) {
          worksheet.addRow([no, item.nama_siswa, item.marketing, item.angkatan_2, item.tgl_bayar, parseInt(item.jumlah_fix), '15%', parseInt(item.jumlah_fmi), '', parseInt(item.potongan), parseInt(item.jumlah_fmi_diterima), item.keterangan]);
          no++;
        }
      })

      // Mengatur style untuk header (bold)
      worksheet.getRow(1).font = { bold: true };

      // Border
      const borderedCell = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L']
      borderedCell.forEach((cell) => {
        worksheet.getCell(`${cell}1`).border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          right: { style: 'thin' }
        };

        worksheet.getCell(`${cell}2`).border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' }
        };
      })

      let startIndex = 3;
      this.data.forEach((item) => {
        if (!item.separator) {
          borderedCell.forEach((cell) => {
            worksheet.getCell(`${cell}${startIndex}`).border = {
              left: { style: 'thin' },
              right: { style: 'thin' }
            };
          })
          startIndex += 1;
        }
      })


      // Mengatur lebar kolom
      // worksheet.columns.forEach(column => {
      //   column.width = 15;
      // });

      // Menyimpan workbook sebagai file Excel
      workbook.xlsx.writeBuffer()
        .then(buffer => {
          const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

          const filename = `ACC FMI ${moment(this.filter.tgl_awal.startDate).format('DD-MM-YY')} sampai ${moment(this.filter.tgl_akhir.startDate).format('DD-MM-YY')}.xlsx`;

          if (window.navigator.msSaveOrOpenBlob) {
            // Untuk Internet Explorer
            window.navigator.msSaveOrOpenBlob(blob, filename);
          } else {
            // Untuk browser modern
            const link = document.createElement('a');
            const url = URL.createObjectURL(blob);
            link.href = url;
            link.download = filename;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(url);
          }
        })
        .catch(error => {
          console.error('Terjadi kesalahan:', error);
        });
    },
    sabtuLalu() {
      const today = moment();
      const lastWeek = today.clone().subtract(1, 'weeks');
      const saturdayOfLastWeek = lastWeek.clone().day('Saturday');

      return saturdayOfLastWeek.format('YYYY-MM-DD');
    },
    jumatIni() {
      const today = moment();
      const fridayOfThisWeek = today.clone().day('Friday');

      return fridayOfThisWeek.format('YYYY-MM-DD');
    }
  },
  mounted() {
    this.$store.commit("setting/setActivePage", "fmi");

    this.load();
    this.loadPicKampus();
    this.loadMarketing();
  },
};
</script>

<style lang="scss" scoped>
.top-bar {
  margin-top: 45px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;

  a {
    width: 150px;
    text-align: center;
  }

  .filter-value {
    display: flex;
    align-items: flex-end;
    position: relative;

    p {
      position: absolute;
      top: -30px;
    }

    .filter-status {
      padding: 0;
    }
  }

  .filter {
    position: relative;

    p {
      position: absolute;
      font-size: 14px;
      top: -40px;
    }
  }

  form {
    display: flex;

    .btn {
      width: 120px;

      &.btn-excel {
        background-color: var(--green) !important;
        border: 0 !important;
      }
    }

    &.ho {
      grid-template-columns: 200px 200px 200px 100px;
    }

    grid-column-gap: 15px;

    .btn.btn-filter {
      background-color: var(--green) !important;
      border: 0 !important;
    }
  }
}

.table-wrapper {
  height: calc(100vh - 250px);
  margin-bottom: 20px;

  table {
    // height: 100%;
  }

  td.header {
    background-color: #f7f7f7;
  }

  th,
  td {
    white-space: nowrap;
  }

  tfoot {
    position: sticky;
    bottom: 0;
    background: #eee;
  }
}

input[type="checkbox"] {
  cursor: pointer;
}

.btn-fmi {
  width: 140px;
}
</style>