<template>
  <div class="home">
    <h1>Laporan Transaksi</h1>

    <div class="content" :class="{ grid: $store.state.setting.login.akses == 'HEAD OFFICE' }">
      <div class="side" v-if="$store.state.setting.login.akses == 'HEAD OFFICE'">
        <div class="perusahaan">
          <ul>
            <li v-for="(item, index) in dataPerusahaan" :key="index" :class="{ active: item.id == filter.id_perusahaan }"
              @click="load(item.id)">
              <span>{{ item.nama }}</span>
            </li>
          </ul>
        </div>
      </div>
      <div class="main">
        <div style="display: flex; justify-content: space-between">
          <div>
            <h3>TOTAL : {{ total | number }}</h3>
          </div>
          <div class="filter">
            <p>Filter</p>
            <form @submit.prevent="load(filter.id_perusahaan)">
              <select v-model="filter.id_pos">
                <option value="">SEMUA POS</option>
                <option v-for="(item, index) in dataPos" :key="index" :value="item.id">
                  {{ item.nama }}
                </option>
              </select>
              <div>
                <date-range-picker v-model="dateRange" :auto-apply="true" :ranges="false">
                  <template v-slot:input="picker">
                    {{ moment(picker.startDate).format("DD MMM YYYY") }} -
                    {{ moment(picker.endDate).format("DD MMM YYYY") }}
                  </template>
                </date-range-picker>
              </div>
              <button type="submit" class="btn btn-filter">FILTER</button>
              <button type="button" class="btn btn-excel" @click="excel()">EXCEL</button>
            </form>
          </div>
        </div>

        <div class="table-wrapper">
          <table>
            <thead>
              <tr>
                <th>#</th>
                <th>Tanggal</th>
                <th>Keterangan</th>
                <th style="text-align: right">Jumlah</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in data" :key="index" class="no-hover">
                <td v-show="item.group" colspan="4" style="background-color: #f7f7f7">
                  <b>{{ item.pos }}</b>
                </td>

                <td v-show="item.id">{{ ++index }}</td>
                <td v-show="item.id">
                  {{ moment(item.tgl_transaksi).format("DD MMM YYYY") }}
                </td>
                <td v-show="item.id">{{ item.keterangan }}</td>
                <td v-show="item.id" align="right">
                  {{ item.jumlah | number }}
                </td>

                <td v-show="item.totalPerGroup" colspan="3">
                  <b>TOTAL</b>
                </td>
                <td v-show="item.totalPerGroup" align="right">
                  <b>{{ item.totalPerGroup | number }}</b>
                </td>

                <td v-show="item.empty" colspan="4" style="border-top: 2px solid #ccc">
                  &nbsp;
                </td>
              </tr>
            </tbody>
          </table>

          <div class="loading" ref="loading">
            <div class="dot-loader"></div>
          </div>
          <div v-if="data.length <= 0 && !loading">
            <p style="padding: 60px 0; text-align: center">
              Tidak ada data
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { apiGet, apiPost } from "../api";

import _ from "lodash";
import moment from "moment";

import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

export default {
  name: "Order",
  components: {
    DateRangePicker,
  },
  data() {
    return {
      loading: false,

      dataPerusahaan: [],
      dataPos: [],
      data: [],
      dataExcel: [],

      filter: {
        id_pos: "",
        id_perusahaan: null,
        tgl_transaksi: "",
      },

      dateRange: {
        startDate: moment().format("YYYY-MM-01"),
        endDate: moment().format("YYYY-MM-DD"),
      },

      total: 0,
    };
  },
  methods: {
    moment,
    async loadPerusahaan() {
      const res = await apiGet(`/v1/perusahaan`);

      setTimeout(() => {
        this.dataPerusahaan = res.data;
      }, 150);
    },
    async loadPos() {
      const res = await apiGet(`/v1/pos`);

      setTimeout(() => {
        this.dataPos = res.data;
      }, 150);
    },
    async load(id_perusahaan) {
      this.$refs['loading'].classList.remove('fade-out');
      this.$refs['loading'].style.display = 'flex';
      this.loading = true;

      this.total = 0;

      this.filter.id_perusahaan = id_perusahaan;

      this.filter.tgl_awal = moment(this.dateRange.startDate).format(
        "YYYY-MM-DD"
      );
      this.filter.tgl_akhir = moment(this.dateRange.endDate).format(
        "YYYY-MM-DD"
      );

      const res = await apiGet(
        `/v1/transaksi?${this.$objectToQuery(this.filter)}`
      );

      // build data for excel
      this.dataExcel = [];
      res.data.forEach((item) => {
        const row = {
          tgl_transaksi: item.tgl_transaksi,
          pos: item.pos,
          keterangan: item.keterangan,
          jumlah: item.jumlah
        }
        this.dataExcel.push(row);
      });

      // build data for table

      const dataGroup = _.groupBy(res.data, "pos");
      const posKey = Object.keys(dataGroup).map((item) => {
        if (item != "null") {
          return item;
        }
      });

      const data = [];
      posKey.forEach((key) => {
        if (key) {
          data.push({
            group: true,
            pos: key,
          });
        }

        if (dataGroup[key]) {
          let totalPerGroup = 0;
          dataGroup[key].forEach((item) => {
            data.push(item);
            totalPerGroup += parseInt(item.jumlah);
          });

          data.push({
            totalPerGroup,
          });
          data.push({ empty: true });
        }
      });


      this.$refs['loading'].classList.add('fade-out');
      setTimeout(() => {
        this.$refs['loading'].style.display = 'none';
        this.loading = false;


        this.data = data;

        this.data.forEach((item) => {
          if (item.jumlah) {
            this.total += parseInt(item.jumlah);
          }
        });
      }, 500);

      this.$loading(false);
    },
    async excel() {
      const sortedData = _.orderBy(this.dataExcel, ['pos', 'tgl_transaksi']);
      const perusahaan = this.dataPerusahaan.find((k) => k.id == this.filter.id_perusahaan);
      const title = 'LAPORAN TRANSAKSI ' + perusahaan.nama.toUpperCase();

      const res = await apiPost(`/v1/transaksi/excel?tgl_awal=${this.filter.tgl_awal}&tgl_akhir=${this.filter.tgl_akhir}&title=${title}`, sortedData);
      location.href = res.url;
    }
  },
  mounted() {
    this.loadPerusahaan();
    this.loadPos();

    this.$refs['loading'].style.display = 'none';

    if (this.$store.state.setting.login.akses == "CABANG") {
      this.filter.id_perusahaan = this.$store.state.setting.login.id_perusahaan;
      this.load(this.filter.id_perusahaan);
    }

    this.$store.commit("setting/setActivePage", "laporan");
  },
};
</script>

<style lang="scss" scoped>
.content {
  display: grid;

  &.grid {
    grid-template-columns: 250px 1fr;
    gap: 20px;
  }

  margin-top: 50px;
  height: calc(100vh - 180px);

  .side {
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    height: calc(100vh - 180px);
    overflow-y: auto;

    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;

      li {
        span {
          display: block;
          padding: 15px;
          cursor: pointer;
          border-radius: 6px;

          &:hover {
            background: #eee;
          }
        }

        &.active {
          span {
            background: var(--blue);
            color: #fff;
          }
        }
      }
    }
  }

  .main {
    border-radius: 10px;

    .filter {
      position: relative;
      margin-bottom: 20px;

      p {
        position: absolute;
        font-size: 14px;
        top: -40px;
      }
    }

    form {
      display: grid;
      grid-template-columns: 200px 250px 100px 100px;
      grid-column-gap: 15px;

      .btn {
        &.btn-filter {
          background-color: var(--green) !important;
          border: 0 !important;
        }

        &.btn-excel {
          background-color: #fff;
          color: var(--green) !important;
          border: 1px solid var(--green) !important;

          &:hover {
            background-color: #f7f7f7;
          }

          &:active {
            background-color: var(--green);
            color: #fff !important;
          }
        }
      }
    }
  }
}

.table-wrapper {
  height: calc(100vh - 200px);
  position: relative;

  .loading {
    height: 69%;
  }
}
</style>