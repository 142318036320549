import axios from 'axios';

import router from './../router';
import store from './../store';

const baseUrl = 'https://keuangan.psppcdn.com';
// const baseUrl = 'http://localhost:9001';

const setting = store.state.setting;
let clientToken = '';
if (setting.isLogin) {
  clientToken = setting.login.token;
}

export const apiGet = (url) => {
  return axios
    .get(baseUrl + url, {
      headers: { Authorization: `Basic ${clientToken}` },
    })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      if (e.response.status == 401) {
        localStorage.clear();
        router.push('/login?mesage=Unauthorized');
      }
    });
};

export const rawGet = (url) => {
  return axios
    .get(url, {
      mode: 'no-cors',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      return response.data;
    });
};

export const apiPost = (url, data) => {
  return axios
    .post(baseUrl + url, data, {
      headers: { Authorization: `Basic ${clientToken}` },
    })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      if (e.response.status == 401) {
        localStorage.clear();
        router.push('/login?mesage=Unauthorized');
      }
    });
};

export const apiUpload = (url, data) => {
  return axios
    .post(baseUrl + url, data, {
      headers: {
        Authorization: `Basic ${clientToken}`,
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      if (e.response.status == 401) {
        localStorage.clear();
        router.push('/login?mesage=Unauthorized');
      }
    });
};

export const login = (url, data) => {
  return axios.post(baseUrl + url, data).then((response) => {
    return response.data;
  });
};

export const apiGetNoMiddleware = (url) => {
  return axios.get(baseUrl + url).then((response) => {
    return response.data;
  });
};
